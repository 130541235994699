<template>
  <div>
    <div x-data="{ sidebarOpen: false }" class="flex h-screen bg-gray-100">
      <div :class="sidebarOpen ? 'block' : 'hidden'" @click="sidebarOpen = false" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
      <default-sidebar></default-sidebar>
      <div class="flex-1 flex flex-col overflow-hidden">
        <default-header></default-header>
        <router-view :key="$route.path"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import DefaultHeader from "@/components/admin/DefaultHeader.vue"
import DefaultSidebar from "@/components/admin/DefaultSidebar.vue"

export default {
  name: "LayoutAdmin",
  components: {
    DefaultHeader,
    DefaultSidebar
  },

  data() {
    return {
      // isOpen: false,
      sidebarOpen: '',
    };
  },
}

</script>
