import Vue from "vue";
import VueRouter from "vue-router";
// import Store from '@/store'

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import LayoutUser from "@/layouts/LayoutUser.vue";
import LayoutAdmin from "@/layouts/LayoutAdmin.vue";
// import LayoutAdminBreadcrumb from "@/layouts/LayoutAdminBreadcrumb.vue";


import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import HslKelitbangan from "@/views/HasilKelitbangan.vue";
import Pencarian from "@/views/Pencarian.vue";
import HslUsulan from "@/views/Usulan.vue";
import DetailPenelitian from "@/views/DetailPenelitianHome.vue";
import DetailUsulan from "@/views/DetailUsulan.vue";

import UserDashboard from "@/views/user/Dashboard.vue";
import Usulan from "@/views/user/UsrUsulan.vue";
import addPenelitian from "@/views/user/TambahPenelitian.vue";
import addUsulan from "@/views/user/TambahUsulan.vue";
import editUsulan from "@/views/user/TambahUsulan.vue";
import editPenelitian from "@/views/user/EditPenelitian.vue";
import DaftarPenelitian from "@/views/user/DaftarPenelitian.vue";
import UserProfile from "@/views/user/UserProfile.vue";

import Dashboard from "@/views/admin/Dashboard.vue";
import User from "@/views/admin/User.vue";
import RIK from "@/views/admin/RIK.vue";
import Bidang from "@/views/admin/Bidang.vue";

import LayoutOPD from "@/views/admin/opd/layoutOPD.vue";
import OPD from "@/views/admin/opd/OPD.vue";
import addOPD from "@/views/admin/opd/addOPD.vue";

import Tema from "@/views/admin/Tema.vue";

import adminKelitbangan from "@/views/admin/kelitbangan/HasilKelitbangan.vue";
import detailKelitbangan from "@/views/admin/kelitbangan/DetailKelitbangan.vue";
import LayoutKelitbangan from "@/views/admin/kelitbangan/LayoutKelitbangan.vue";

import adminUsulan from "@/views/admin/Usulan.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home Page",
      layout: LayoutDefault,
      requiresAuth: false
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login Page",
      requiresAuth: false
    },
  },

  {
    path: "/hasilkelitbangan",
    name: "HasilKelitbangan",
    component: HslKelitbangan,
    props: true,
    meta: {
      title: "Home Page",
      layout: LayoutDefault,
      requiresAuth: false
    },
  },

  {
    path: "/pencarian/:query",
    name: "Pencarian",
    component: Pencarian,
    meta: {
      title: "Pencarian",
      layout: LayoutDefault,
      requiresAuth: false
    },

  },
  {
    path: "/usulan",
    name: "Usulan",
    component: HslUsulan,
    meta: {
      title: "Usulan",
      layout: LayoutDefault,
      requiresAuth: false
    },
  },
  {
    path: "/detailusulan/:proposal_id",
    name: "DetailUsulanHome",
    title: "Detail Usulan",
    component: DetailUsulan,
    props: true,
    meta: {
      layout: LayoutDefault,
      auth: false
    }
  },
  {
    path: "/detailkelitbangan/:studies_id",
    name: "DetailPenelitianHome",
    title: "Detail Penelitian",
    component: DetailPenelitian,
    props: true,
    meta: {
      layout: LayoutDefault,
      auth: false
    }
  },

  // USER
  {
    path: "/user",
    redirect: "/user/dashboard",
    name: "User",
    component: LayoutUser,
    children: [
      {
        path: "/user/dashboard",
        name: "Dashboard",
        title: "Dashboard",
        component: UserDashboard,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/hasilusulan",
        name: "Hasil Usulan User",
        title: "Usulan",
        component: Usulan,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/tambahkelitbangan",
        name: "TambahPenelitian",
        title: "Tambah Penelitian",
        component: addPenelitian,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/tambahusulan",
        name: "TambahUsulan",
        title: "Tambah Usulan",
        component: addUsulan,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/editusulan/:id",
        name: "EditUsulan",
        title: "Edit Usulan",
        component: editUsulan,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/detailusulan/:proposal_id",
        name: "DetailUsulan",
        title: "Detail Usulan",
        component: DetailUsulan,
      },
      {
        path: "/user/editkelitbangan/:studies_id",
        name: "EditKelitbangan",
        title: "Edit Kelitbangan",
        component: editPenelitian,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/detailkelitbangan/:studies_id",
        name: "DetailPenelitian",
        title: "Detail Penelitian",
        component: DetailPenelitian,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/daftarkelitbangan",
        name: "DaftarPenelitian",
        title: "Daftar Penelitian",
        component: DaftarPenelitian,
        meta: {
          auth: true
        }
      },
      {
        path: "/user/profilsaya",
        name: "ProfilSaya",
        title: "Profil Saya",
        component: UserProfile,
        meta: {
          auth: true
        }
      },
    ]
  },

  // ADMIN
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    name: "Admin Dasboard",
    component: LayoutAdmin,
    meta: {
      breadcrumb: {
        label: 'Home'
      }
    },
    children: [
      {
        path: "dashboard",
        name: "Admin Dashboard",
        component: Dashboard,
        meta: {
          adminAuth: true,
          breadcrumb: 'Dashboard'
        }
      },
      {
        path: "user",
        name: "Admin User",
        component: User,
        meta: {
          adminAuth: true,
          breadcrumb: 'Users'
        }
      },
      {
        path: "rik",
        name: "RIK",
        component: RIK,
        meta: {
          adminAuth: true,
          breadcrumb: 'RIK'
        }
      },
      {
        path: "bidang",
        name: "Bidang",
        component: Bidang,
        meta: {
          adminAuth: true,
          breadcrumb: 'Bidang'
        }
      },

      {
        path: "opd",
        component: LayoutOPD,
        meta: {
          adminAuth: true,
          breadcrumb: 'OPD'
        },
        children: [
          {
            path: '/',
            name: 'dataOPD',
            component: OPD,
          },
          {
            path: 'tambahopd',
            name: 'TambahOPD',
            component: addOPD,
            meta:{
              adminAuth: true,
              breadcrumb: 'Tambah OPD'
            }
          },
          {
            path: ':id',
            name: 'EditOPD',
            component: addOPD,
            meta:{
              adminAuth: true,
              breadcrumb: 'Edit OPD'
            }
          }
        ]
      },

      {
        path: "tema",
        name: "Tema",
        component: Tema,
        meta: {
          adminAuth: true,
          breadcrumb: "Tema"
        }
      },
      {
        path: "hasil-kelitbangan",
        component: LayoutKelitbangan,
        meta: {
          adminAuth: true,
          breadcrumb: {
            label: 'Data Kelitbangan',
            parent: 'Dashboard'
          }
        },
        children: [
          {
            path: '/',
            name: 'dataKelitbangan',
            component: adminKelitbangan,
            meta: {
              adminAuth: true,
            }
          },
          {
            path: 'detailkelitbangan/:studies_id',
            name: 'detailkelitbangan',
            component: detailKelitbangan,
            meta:{
              adminAuth: true,
              breadcrumb: {
                label: "Detail",
              }
            }
          },
        ]
      },

      {
        path: "usulan",
        name: "adminUsulan",
        component: adminUsulan,
        meta: {
          adminAuth: true,
          breadcrumb: "Usulan"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth || record.meta.adminAuth || record.meta.auth)){
    if(localStorage.getItem('token') === null){
      next('/login');
    } else {
      next();
    }
  }
  else if(to.matched.some(record => record.meta.adminAuth)){
    if(localStorage.getItem('token') === null && (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "superadmin")){
      alert("Anda Bukan Admin")
      next('/login');
    } else {
      next();
    }
  }
  else {
    next();
  }
}))

export default router;
