<template>	
	<main class="flex-1 overflow-x-hidden overflow-y-auto">
		<div class="container mx-auto px-6 py-8">
			<h3 class="text-gray-700 text-3xl font-medium">Dashboard</h3>

			<!-- STATS -->
			<div class="mt-4">
				<div class="flex flex-wrap -mx-6">
					<div class="w-full px-6 sm:w-1/2 xl:w-1/3 py-4">
						<div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<div class="p-3 rounded-full bg-gray-600">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="white">
									<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
								</svg>
							</div>

							<div class="mx-5">
								<h4 class="text-2xl font-semibold text-gray-700">{{getUsers.meta.page.total}}</h4>
								<div class="text-gray-500">Total User</div>
							</div>
						</div>
					</div>

					<div class="w-full px-6 sm:w-1/2 xl:w-1/3 py-4">
						<div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
							</div>

							<div class="mx-5">
								<h4 class="text-2xl font-semibold text-gray-700">{{getRiks.meta.page.total}}</h4>
								<div class="text-gray-500">Total RIK</div>
							</div>
						</div>
					</div>

					<div class="w-full px-6 sm:w-1/2 xl:w-1/3 py-4">
						<div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
							</div>

							<div class="mx-5">
								<h4 class="text-2xl font-semibold text-gray-700">{{getSectors.meta.page.total}}</h4>
								<div class="text-gray-500">Total Bidang</div>
							</div>
						</div>
					</div>

					<div class="w-full px-6 sm:w-1/2 xl:w-1/3 py-4">
						<div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
									<path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
								</svg>
							</div>

							<div class="mx-5">
								<h4 class="text-2xl font-semibold text-gray-700">{{getThemes.meta.page.total}}</h4>
								<div class="text-gray-500">Total Tema</div>
							</div>
						</div>
					</div>

					<div class="w-full px-6 sm:w-1/2 xl:w-1/3 py-4">
						<div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
                </svg>
							</div>

							<div class="mx-5">
								<h4 class="text-2xl font-semibold text-gray-700">{{getOpds.meta.page.total}}</h4>
								<div class="text-gray-500">Total OPD</div>
							</div>
						</div>
					</div>

				</div>
			</div>

      
			<div class="flex flex-col mt-8">
				<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div
						class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Dashboard",
  components: {
    //SearchHeader
  },
	data() {
		return {
			open: false
		}
	},
	created(){
		this.fetchAllUsers({
			attribute_name: 'search-users',
			keyword: '',
			pageNumber: 1,
			pageSize: 1,
			sortName: 'updatedAt',
			sortType: 'desc'
		});
		this.fetchAllRiks({
			attribute_name: 'search-start-or-end-year',
			keyword: '',
			pageNumber: 1,
			pageSize: 1,
			sortName: 'startYear',
			sortType: 'desc'
		});
		this.fetchAllSectors({
			attribute_name: 'search-name-or-description',
			keyword: '',
			pageNumber: 1,
			pageSize: 1,
			sortName: 'updatedAt',
			sortType: 'desc'
		});
		this.fetchAllOpds({
			attribute_name: 'search-name-or-description',
			keyword: '',
			pageNumber: 1,
			pageSize: 1,
			sortName: 'updatedAt',
			sortType: 'desc'
		});
		this.fetchAllThemes({
			attribute_name: 'search-name-or-description',
			keyword: '',
			pageNumber: 1,
			pageSize: 1,
			sortName: 'updatedAt',
			sortType: 'desc'
		});
	},
	computed: {
		...mapGetters({
			getUsers: 'users/getUsers',
			getRiks: 'riks/getPaginateRiks',
			getSectors: 'sectors/getPaginateSectors',
			getOpds: 'opds/getPaginateOpds',
			getThemes: 'themes/getPaginateThemes',
			getPermissions: 'permissions/getPermissions'
		})
	},
	methods: {
		...mapActions({
			fetchAllUsers: 'users/fetchAllUsers',
			fetchAllRiks: 'riks/fetchPaginateRiks',
			fetchAllSectors: 'sectors/fetchPaginateSectors',
			fetchAllOpds: 'opds/fetchPaginateOpds',
			fetchAllThemes: 'themes/fetchPaginateThemes',
			fetchAllPermissions: 'permissions/fetchAllPermissions'
		})
	}
};
</script>
<style>
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  list-style: none;
  background-color: rgb(66, 185, 131, 0.2);
  margin-top: 0;
  margin-bottom: 16px;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 6px;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block; 
  color: #2c3e50;
  content: '/';
}
.breadcrumb-item a {
  font-size: 14px;
  font-weight: bold;
  color: #2c3e50;
}
.breadcrumb-item a:hover {
  text-decoration: underline;
}
.breadcrumb-item span {
  font-size: 14px;
  font-weight: bold;
  color: #596a7a;
}
</style>