<template>
    <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
      <div class="my-10" >
        <div class="container flex flex-col justify-between items-center relative">
          <div class="shadow flex w-full">
          <input
            class="w-full rounded-lg py-2"
            type="text"
            placeholder="Cari Penelitian..."
            v-model="keyword"
          >
          </div>
          {{this.query}}
        </div>
        
        <div class="grid grid-cols-3 lg:grid lg:grid-cols-12 lg:gap-x-5 mt-6">
          <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Filter</h3>
            <nav class="space-y-1 mt-4">
              <div class="overflow-hidden">
                <button @click="toggleRIK" :class="{'bg-gray-50': openRIK}" v-if="toggleRIK" key="on" type="button" class="hover:text-gray-900 hover:bg-gray-50 bg-white cursor-pointer relative rounded-md w-full px-4 py-2 text-left cursor-default focus:outline-none sm:text-sm" aria-expanded="true">
                  <span class="truncate font-medium">
                    RIK
                  </span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <svg :class="{'putar-90' : openRIK}" class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="currentColor" fill="none" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </button>

                <transition name="slide">
                <div class="px-4 py-5 sm:p-6 divide-y divide-gray-200 bg-gray-50 rounded-b-md"  v-if="openRIK">
                  <div class="mt-4 sm:mt-0 sm:col-span-2">
                    <div class="max-w-lg space-y-4">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" type="radio" v-model="search.rik_id" name="radioRiks" value="" @change="filteredByRIK" checked="checked"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label class="font-medium text-gray-700">All</label>
                        </div>
                      </div>
                      <div class="relative flex items-start" 
                        v-for="rik in getRiks"
                        :key="rik.id">
                        <div class="flex items-center h-5">
                          <input :value="rik.id" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="search.rik_id" @change="filteredByRIK"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label class="font-medium text-gray-700">{{ rik.attributes.endYear }}</label>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                </transition> 
              </div>

              <div class="overflow-hidden">
                <button @click="toggleSector" :class="{'bg-gray-50': openSector}" v-if="toggleSector" key="on" type="button" class="hover:text-gray-900 hover:bg-gray-50 bg-white cursor-pointer relative rounded-md w-full px-4 py-2 text-left cursor-default focus:outline-none sm:text-sm" aria-expanded="true">                  
                  <span class="truncate font-medium">
                    Bidang
                  </span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <svg :class="{'putar-90' : openSector}" class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="currentColor" fill="none" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </button>

                <transition name="slide">
                <div class="px-4 py-5 sm:p-6 divide-y divide-gray-200 bg-gray-50 rounded-b-md"  v-if="openSector">
                  <div v-if="loading" class="flex space-x-4 animate-pulse">
                    <div class="flex-1 space-y-4 py-1">
                      <div class="space-y-2">
                        <div class="h-4 bg-gray-400 rounded"></div>
                      </div>
                    </div>      
                  </div>
                  <div v-else class="mt-4 sm:mt-0 sm:col-span-2">
                    <div class="max-w-lg space-y-4">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" type="radio" v-model="search.sector_id" name="radioSector" value="" @change="filteredBySector" checked/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label class="font-medium text-gray-700">All</label>
                        </div>
                      </div>
                      
                      <div class="relative flex items-start" 
                        v-for="sector in getSectorByRIK"
                        :key="sector.id">
                        <div class="flex items-center h-5">
                          <input id="sector" :value="sector.id" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="search.sector_id" @change="filteredBySector"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label class="font-medium text-gray-700">{{ sector.attributes.name }}</label>
                        </div>
                      </div>                    
                    </div>
                  </div> 
                </div>
                </transition> 
              </div>

              <div class="overflow-hidden">
                <button @click="toggleOpd" :class="{'bg-gray-50': openOpd}" v-if="toggleOpd" key="on" type="button" class="hover:text-gray-900 hover:bg-gray-50 bg-white cursor-pointer relative rounded-md w-full px-4 py-2 text-left cursor-default focus:outline-none sm:text-sm" aria-expanded="true">
                  <span class="truncate font-medium">
                    OPD
                  </span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <svg :class="{'putar-90' : openOpd}" class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="currentColor" fill="none" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </button>

                <transition name="slide">
                <div class="px-4 py-5 sm:p-6 divide-y divide-gray-200 bg-gray-50 rounded-b-md"  v-if="openOpd">
                  <div class="mt-4 sm:mt-0 sm:col-span-2">
                    <div class="max-w-lg space-y-4">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" type="radio" name="radioOpd" value="" v-model="search.opd_id" @change="filteredByOpd" checked="checked"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label class="font-medium text-gray-700">All</label>
                        </div>
                      </div>

                      <div 
                        class="relative flex items-start" 
                        v-for="opd in getOpdsBySector"
                        :key="opd.id">
                        <div class="flex items-center h-5">
                          <input id="opd" :value="opd.id" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="search.opd_id" @change="filteredByOpd"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label  class="font-medium text-gray-700">{{ opd.attributes.name }}</label>
                        </div>
                      </div>                    
                    </div>
                  </div> 
                </div>
                </transition> 
              </div>

              <div class="overflow-hidden">
                <button @click="toggleTheme" :class="{'bg-gray-50': openTheme}" v-if="toggleTheme" key="on" type="button" class="hover:text-gray-900 hover:bg-gray-50 bg-white cursor-pointer relative rounded-md w-full px-4 py-2 text-left cursor-default focus:outline-none sm:text-sm" aria-expanded="true">
                  <span class="truncate font-medium">
                    Tema
                  </span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <svg :class="{'putar-90' : openTheme}" class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="currentColor" fill="none" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </button>

                <transition name="slide">
                <div class="px-4 py-5 sm:p-6 divide-y divide-gray-200 bg-gray-50 rounded-b-md"  v-if="openTheme">
                  <div class="mt-4 sm:mt-0 sm:col-span-2">
                    <div class="max-w-lg space-y-4">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" type="radio" name="radioTheme" value="" v-model="search.theme_id" @change="filteredByTheme" checked="checked"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label class="font-medium text-gray-700">All</label>
                        </div>
                      </div>

                      <div 
                        class="relative flex items-start" 
                        v-for="theme in getThemes"
                        :key="theme.id">
                        <div class="flex items-center h-5">
                          <input id="theme" name="comments" :value="theme.id" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="search.theme_id" @change="filteredByTheme"/>
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="comments" class="font-medium text-gray-700">{{ theme.attributes.name }}</label>
                        </div>
                      </div>                    
                    </div>
                  </div> 
                </div>
                </transition> 
              </div>
            </nav>
          </aside>

          <div class=" col-span-2 sm:px-2 sm:py-6 lg:px-0 lg:col-span-9">
            <div v-if="loading != loading" class="w-1/3 container mx-auto px-6 flex flex-col justify-between items-center relative">
              <Loading></Loading>  
            </div>
            <div class="px-2 sm:px-0">
              <ul
                class="space-y-4" v-for="hasil in filteredData" :key="hasil.id"
              >
                <li class="bg-white px-4 py-6 shadow sm:p-6 rounded-lg border mt-6">
                  <article>
                    <div>
                      <div
                        class="mt-1 flex justify-between items-baseline md:block lg:flex"
                      >
                        <div class="flex items-baseline text-2xl font-bold">
                          {{ hasil.attributes.title }}
                        </div>

                        <div
                          class="status-draft"
                        >
                          {{ hasil.attributes.status }}
                        </div>
                      </div>
                      <h2               
                        class="text-base font-medium text-gray-900 text-xs"
                      >{{hasil.attributes.team}}</h2>
                    </div>
                    <div class="mt-2 text-sm text-gray-700 space-y-4">
                      <p class="overflow-ellipsis overflow-hidden">
                        {{ hasil.attributes.background }}
                      </p>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
                 
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loading from '@/components/LoadingSVG.vue'

export default {
  name: 'Pencarian',
  components: {
    Loading,
  },

  data() {
    return {
      loading: '',
      keyword: '',
      search: {
        rik_id: '',
        sector_id: '',
        opd_id: '',
        theme_id: ''
      },

      openRIK: true,
      openSector: false,
      openOpd: false,
      openTheme: false,
    }
  },

  computed: {
    ...mapGetters({
      getRiks: 'riks/getRiks',
      getSectorByRIK: 'sectors/getSectorByRIK',
      getOpdsBySector: 'sectors/getOpdsBySector',
      getThemes: 'themes/getThemesByOpd',
      getOpd: 'sectors/getOpd',
      getResults: 'proposals/getProposalSearchResult'
    }),

    filteredData() {
      return this.getResults.filter(post => {
        return post.attributes.title.toLowerCase().includes(this.keyword.toLowerCase())
      });
    }

  },
  methods: {
    ...mapActions({
      fetchAllRiks: 'riks/fetchAllRiks',
      fetchSectorsByRiks: 'sectors/fetchSectorsByRiks',
      fetchOpdsBySector: 'sectors/fetchOpdsBySector',
      fetchThemesByOpds: 'themes/fetchThemesByOpds',
      searchResult: 'proposals/searchAllProposals',
      
      filterByRIK: 'proposals/filterProposalByRIK',
      filterBySector: 'proposals/filterProposalBySector',
      filterByOpd: 'proposals/filterProposalByOpd',
      filterByTheme: 'proposals/filterProposalByThemes'
    }),

    ...mapMutations({
      clearSector: 'sectors/CLEAR_SECTOR_BY_RIK',
      clearOpds: 'sectors/CLEAR_OPD_BY_SECTOR',
      clearThemes: 'themes/CLEAR_THEMES_BY_OPD'
    }),

    toggleRIK() {
      return this.openRIK = !this.openRIK;
    },

    toggleSector() {
      return this.openSector = !this.openSector;
    },

    toggleOpd() {
      return this.openOpd = !this.openOpd;
    },

    toggleTheme() {
      return this.openTheme = !this.openTheme;
    },

    filteredByRIK(){
      this.loading = true
      if(this.search.rik_id == '') {
        this.filterByRIK({
          rik_id: this.search.rik_id
        })
        this.openSector = this.openOpd = this.openTheme = false
        this.search.sector_id = this.search.opd_id = this.search.theme_id = ''
        this.clearSector();
        this.clearOpds();
        this.clearThemes();
      } else {
        this.filterByRIK({
          rik_id: this.search.rik_id
        })
        this.fetchSectorsByRiks(this.search.rik_id);
        this.openSector = true
      }
      this.loading = false
    },

    filteredBySector(){
      if(this.search.sector_id == '') {
        this.openOpd = this.openTheme = false
        this.search.opd_id = this.search.theme_id = ''
        this.clearOpds();
        this.clearThemes();
      } else {
        this.filterBySector({
          rik_id: this.search.rik_id, 
          sector_id: this.search.sector_id
        })
        this.fetchOpdsBySector(this.search.sector_id);
        this.openOpd = true
      }
      
      // this.fetchSectorsByRiks(this.search.rik_id);
    },

    filteredByOpd(){
      if(this.search.opd_id == '') {
        this.openTheme = false
        this.search.theme_id = ''
        this.clearThemes();
      } else {
        this.filterByOpd({
          rik_id: this.search.rik_id, 
          sector_id: this.search.sector_id, 
          opd_id: this.search.opd_id
        })
        this.fetchThemesByOpds(this.search.opd_id)
        this.openTheme = true
      }
    },

    filteredByTheme(){
      if(this.search.theme_id != '') {
        this.filterByTheme({
          rik_id: this.search.rik_id, 
          sector_id: this.search.sector_id, 
          opd_id: this.search.opd_id,
          theme_id: this.search.theme_id
        })
      }
    }
  },
  created(){
    this.searchResult(this.keyword)
    this.fetchAllRiks();
    this.filterByRIK({
      rik_id: this.search.rik_id
    })
  }
}
</script>