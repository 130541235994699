import axios from 'axios';
import router from '../../router';

const state = {
	status: '',
	token: localStorage.getItem('token') || '',
	user: '',
	authenticationError: '',
	authenticationErrorCode: 0,
	apiUrl: process.env.VUE_APP_BASEURL + "/api/v1"
};

const mutations = {
	authRequest(state) {
		state.status = "loading";
		state.authenticationErrorCode = 0;
		state.authenticationError = '';
	},

	authSuccess(state, {token, user}) {
		state.status = "success";
		state.token = token;
		state.user = user;
	},

	authError(state, {errorCode, errorMessage}) {
		state.status = "error"
		state.authenticationError = errorMessage;
		state.authenticationErrorCode = errorCode;
	},

	logoutSuccess(state) {
		state.token = '';
		state.status = '';
		state.user = '';
	}
};

const getters = {
	isLoggedIn: (state) => {
		return !!state.token
	},

	authenticationErrorCode: (state) => {
		return state.authenticationErrorCode
	},

	authenticationError: (state) => {
		return state.authenticationError
	},

	authStatus: (state) => {
		return state.status
	},

	authUser: (state) => {
		return state.user
	}
};

const actions = {
	async login({ commit, state }, user) {
		return new Promise((resolve) => {
			commit('authRequest');

			axios({
				url: state.apiUrl + '/auth/login',
				data: user,
				method: 'POST',
				headers: {
					'Accept' : 'application/vnd.api+json',
					'Content-Type' : 'application/vnd.api+json'
				}
			})
				.then((resp) => {
					const token = resp.data.data.token;
					const me = resp.data.data.user;
					console.log(resp.data)

					localStorage.setItem('token', token);
					localStorage.setItem('me', JSON.stringify(me));
					
					// axios.defaults.headers.common['Authorization'] = token;
					axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

					commit('authSuccess', {token: token, user: me});
					resolve(resp);
				})
				.catch((err) => {
					commit('authError', {errorCode: err.response.status, errorMessage: err.response.data.message})
				})
		})
	},

	logout({ commit }) {
		return new Promise((resolve) => {
			localStorage.clear();
			commit('logoutSuccess')
			delete axios.defaults.headers.common['Authorization'];
			router.push('/login');
			resolve();
		})
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
