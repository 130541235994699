import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  rik: {},
  riks: {
    data: {
      
    }
  },
  paginateRiks: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {}
  }
};

const getters = {
  getRiks: (state) => {
    // console.log("RIKS: "+JSON.stringify(state.riks.data))
    return state.riks.data
  },
  getRIK: (state) => {
    return state.rik
  },
  getPaginateRiks: (state) => {
    return state.paginateRiks
  }
};

const mutations = {
  REQUEST_RIKS(state, status) {
    state.isLoading = status
  },

  SET_RIKS(state, response) {
    state.riks = response
  },

  SET_RIK(state, response) {
    state.rik = response
  },

  SET_PAGINATE_RIKS(state, response) {
    state.paginateRiks = response
  }
};

const actions = {
  async createRIK({commit}, {data, config}){
    commit('REQUEST_RIKS', true)
    try {
      let response = await ApiService.post('/api/v1/riks', data, config)
      commit('SET_RIK', response.data)
    } catch(error) {
      console.log('Create RIK error : ' + error)
    }
    commit('REQUEST_RIKS', false)
  },

  async updateRIK({commit}, {data, config}){
    commit('REQUEST_RIKS', true)
    const form = JSON.parse(data)
    try {
      let response = await ApiService.patch('/api/v1/riks/' + form.data.id, data, config)
      commit('SET_RIK', response.data)
    } catch(error) {
      console.log('Update RIK error : ' + error)
    }
    commit('REQUEST_RIKS', false)
  },

  async fetchAllRiks({commit}) {
    commit('REQUEST_RIKS', true)

    try {
      let response = await ApiService.get('/api/v1/riks')
      let total = response.data.meta.page.total
      let totalResponse = await ApiService.get('/api/v1/riks?page[number]=1&page[size]=' + total )
      commit('SET_RIKS', totalResponse.data)
    } catch (e) {
      console.log('FetchAllRiks : ' + e)
    }
    commit('REQUEST_RIKS', false)
  },

  async fetchPaginateRiks({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_RIKS', true)
    if(sortType=='desc'){
      sortName = '-' + sortName
    }
    if(keyword !== ''){
      try {
        let response = await ApiService.get('/api/v1/riks?filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
        commit('SET_PAGINATE_RIKS', response.data)
      } catch (e) {
        console.log('FetchAllRiks : ' + e)
      } 
    } else {
      try {
        let response = await ApiService.get('/api/v1/riks?page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
        commit('SET_PAGINATE_RIKS', response.data)
      } catch (e) {
        console.log('FetchAllRiks : ' + e)
      } 
    }
    commit('REQUEST_RIKS', false)
  }
}

export default {
	namespaced: true,
  state,
  getters,
  mutations,
  actions
}