<template>
	<div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
		<div class="my-10 space-y-8 divide-y divide-gray-200" >
			<div class="space-y-8 divide-y divide-gray-200">
					<div>
						<h3 class="text-2xl font-semibold text-gray-900">
								Daftar Hasil Kelitbangan
						</h3>
						<!-- <p class="mt-1 text-sm text-gray-500">Menambah Penelitian</p> -->
					</div>

					<div class="mt-8 px-2 sm:px-0" v-for="studies in getStudiesByUser" :value="studies.id" :key="studies.id">
              <ul class="space-y-4">
                <li class="bg-white px-4 py-6 shadow sm:p-6 rounded-lg border">
                  <article aria-labelledby="list-user-studies">
                    <div>
                      <div class="flex justify-between items-baseline md:block lg:flex">
                        <router-link
                          :to="'/user/detailkelitbangan/'+studies.id"
                          class="text-base font-medium text-gray-900 hover:text-indigo-700"
                        >
                          <span class="flex items-baseline text-2xl">
                            {{ studies.attributes.title }}
                          </span>
                        </router-link>
                        <div class="flex justify-between self-center md:block lg:flex">
                          <div :class="[(studies.attributes.status === 'draft' ? 'status-draft' : ''), (studies.attributes.status === 'rejected' ? 'status-rejected' : ''), (studies.attributes.status === 'submitted' ? 'status-submited' : ''), (studies.attributes.status === 'approved' ? 'status-approved' : '')]">
                            {{ studies.attributes.status }}
                          </div>
                        </div>
                      </div>
                      <h2 class="text-base font-medium text-gray-900 text-xs mt-2">
                      <span class="text-sm font-normal">Disusun Oleh:</span> {{studies.attributes.team}}</h2>
                    </div>
                    <div class="mt-2 text-base text-gray-700 overflow-ellipsis overflow-hidden">
                      <p class="overflow-ellipsis overflow-hidden">
                        {{ studies.attributes.background | truncate(500) }}
                      </p>
                    </div>
                  </article>
                </li>
              </ul>
          </div>
          <paginate 
            :page-count="parseInt(getPageCount)"
            :page-range="2"
            :click-handler="paginateStudies"
            :prev-text="'Prev'"
            :prevClass="'-mt-px w-0 flex-1 flex'"
            :prev-link-class="'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
            :next-text="'Next'"
            :nextClass="'-mt-px w-0 flex-1 flex justify-end'"
            :next-link-class="'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
            :page-class="'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
            :activeClass="'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
            :container-class="'border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'">
          </paginate>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Paginate from 'vuejs-paginate';
export default {
  components: {
    'paginate': Paginate
  },
  data: function () {
    return {
      // isOpen: false,
      show: false,
      img: "@/assets/avatar-placeholder.png",
      stats: [
        { name: 'Total Penelitian Diterima', stat: '15' },
        { name: 'Total Penelitian Ditolak', stat: '10' },
        { name: 'Total Penelitian Diajukan', stat: '56' },
      ]
    };
  },
  computed: {
    ...mapGetters({
      getStudiesByUser: 'users/getStudiesByUser',
      getPageCount: 'users/getPageCountUserStudies'
    }),

  },
  methods: {
    ...mapActions({
      fetchStudies: 'users/fetchStudiesByUser'
    }),
    paginateStudies(pageNumber){
      this.fetchStudies({
        pageNumber: pageNumber,
        pageSize: 10
      })
    }
  },
  created(){
    this.fetchStudies({
      pageNumber: 1,
      pageSize: 10
    });
  }
};
</script>