import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  studies: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {}
  },
  pagination: {},
  submit: {},
  file: {},
  detailsRIK: {
    id: '',
    attributes: {
      startYear: '',
      endYear: ''
    }
  },
  details: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {}
  },
  study_id:null,
  studiesSearchResult: {
    data: {},
    meta: {}
  },
  StudiesByUser: {
		data: {},
		meta: {
			page: {
			}
		}
	},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading
  },

  getStudies: (state) => {
    return state.studies
  },

  getMetaStudies: (state) => {
    // return console.log("META: "+JSON.stringify(state.studies.meta))
    return state.studies.meta
  },

  getMetaDetails: (state) => {
    // return console.log("META: "+JSON.stringify(state.details.meta))
    return state.details.meta
  },

  getPagination(state){
		return state.pagination;
	},

  getDetailStudy: (state) => {
    // return console.log("DETAILS: "+JSON.stringify(state.details))
    return state.details
  },

  getDetailsRIK: (state) => {
    return state.detailsRIK
  },

  getStudiesSearchResult: (state) => {
		return state.studiesSearchResult.data
	},

  getPageCountSearchResult: (state) => {
    return state.studiesSearchResult.meta.lastPage
  },

  getMetaSearchResult: (state) => {
    return state.studiesSearchResult.meta
  },

  getSubmitData: (state) => {
    // return console.log("DETAILS SUBMIT: "+JSON.stringify(state.submit))
    return state.submit
  },

  getStudiesByUser: (state) => {
		return state.studiesByUser.data
	},

  getPageCountUserStudies: (state) => {
		return state.studiesByUser.meta.lastPage
	},

  getThemesID: (state) => {
    let theme_id = state.details.data.included.filter((include) => {
      if(include.type=='themes'){
        return include.id
      }
    })
    // return console.log('THEME: '+theme_id)
    return theme_id[0]
  },

  getSectorID: (state) => {
    let sector_id = state.details.data.included.filter((include) => {
      if(include.type=='sectors'){
        return include.id
      }
    })
    // return console.log('THEME: '+sector_id)
    return sector_id[0]
  }
};

const mutations = {
  REQUEST_STUDIES(state, status) {
    state.isLoading = status
  },

  SET_STUDIES(state, response) {
    state.studies = response
  },

  SET_PAGINATION(state, response){
		state.pagination = response;
	},

  STUDY_DETAILS(state, response){
    state.details = response
  },

  STUDY_DETAILS_RIK(state, response){
    state.detailsRIK = response.data
  },

  SET_STUDIES_SEARCH_RESULT(state, response){
		state.studiesSearchResult.data = response.data;
    state.studiesSearchResult.meta = response.meta.page;
	},

  SET_FORM_SUBMIT(state, response){
    state.submit = response
  },
  
  SET_UPLOAD_FILE(state, response){
    state.file = response
  },

  SET_STUDY_ID(state, response){
    state.study_id = response
  },

  SET_USER_STUDIES(state,response){
    state.StudiesByUser.data = response.data
		state.StudiesByUser.meta = response.meta.page
  }
};

const actions = {
  async fetchAllStudies({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_STUDIES', true)
    if(sortType=='desc'){
      sortName = '-' + sortName
    }
    try {
      let response = await ApiService.get('/api/v1/studies?include=user,theme,opd,sector&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
      commit('SET_STUDIES', response.data);
    } catch (e) {
      console.log('FetchAllStudies : ' + e)
    }
    commit('REQUEST_STUDIES', false)
  },

  async fetchStudiesByUser({commit}, {pageNumber, pageSize}) {
		let user = JSON.parse(localStorage.getItem('me'));
		commit('REQUEST_STUDIES', true)

		try {
			let response = await ApiService.get('/api/v1/users/' + user.uuid + '/studies?page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=-updatedAt')
			commit('SET_USER_STUDIES', response.data)
		} catch (e) {
			console.log('Fetch Studies by User : ' + e)
		}
		commit('REQUEST_STUDIES', false)
	},

  async recentStudies({commit}, {pageNumber, pageSize}) {
    commit('REQUEST_STUDIES', true)

    try {
      let response = await ApiService.get('/api/v1/studies?page[number]=' + pageNumber + '&page[size]='+ pageSize + '&sort=-updatedAt&include=opd')
      commit('STUDY_DETAILS', response.data);
    } catch (e) {
      console.log('FetchAllStudies : ' + e)
    }
    commit('REQUEST_STUDIES', false)
  },

  async exportStudies({commit}, {year, config}) {
    commit('REQUEST_STUDIES', true)
    console.log(year)
    try {
      await ApiService.get(
        '/api/v1/studies/export?filter[year]=' + year, config, {responseType: 'blob'}
      ).then((response) => {
        console.log(response.data)
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'studies.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click();
      })
    } catch (e) {
      console.log('FetchAllStudies : ' + e)
    }
    commit('REQUEST_STUDIES', false)
  },

  async StudyDetails({commit}, studies_id) {
    commit('REQUEST_STUDIES', true)
    console.log("state.isLoading")
    try {
      let response = await ApiService.get('/api/v1/studies/'+ studies_id + '?include=opd,sector,theme,filestudies')
      let sector_id = ""
      sector_id = response.data.included.filter((include) => {
        if(include.type=='sectors'){
          return include.id
        }
      }).map(function(data) {
        return data.id
      })
      console.log("sector id from study details: "+sector_id)
      let response_sector = await ApiService.get('/api/v1/sectors/' + sector_id + '/rik')
      commit('STUDY_DETAILS', response)
      console.log(response.data)
      commit('STUDY_DETAILS_RIK', response_sector.data)
    } catch (e) {
      console.log('Fetch Study error : ' + e)
    }
    commit('REQUEST_STUDIES', false)
  },

  async searchStudiesByResource({commit}, {resource_name, resource_id}) {    
    commit('REQUEST_STUDIES', true)

    try {
      let response = await ApiService.get('/api/v1/'+ resource_name + '/' + resource_id + '/studies')
      let seacrhResultStudies = response.data.data;
      commit('SET_STUDIES_SEARCH_RESULT', seacrhResultStudies)
    } catch (e) {
      console.log('Search Studies by ' + resource_name + ' error : ' + e )
    }
    commit('REQUEST_STUDIES', false)
  },

	async searchStudiesByAttribute({commit}, {attribute_name, keyword}) {    
    commit('REQUEST_STUDIES', true)
		if(attribute_name!=='status' && attribute_name!=='all') {
			try {
				let response = await ApiService.get('/api/v1/studies?filter[search-'+ attribute_name + ']=' + keyword)
				let seacrhResultStudies = response.data;
				commit('SET_STUDIES_SEARCH_RESULT', seacrhResultStudies)
			} catch (e) {
				console.log('Search Studies by ' + attribute_name + ' error : ' + e )
			}
		} else if (attribute_name==='all') {
			try {
				let response = await ApiService.get('/api/v1/studies?filter[search-all-columns]=' + keyword)
				let seacrhResultStudies = response.data;
				commit('SET_STUDIES_SEARCH_RESULT', seacrhResultStudies)
			} catch (e) {
				console.log('Search Studies by ' + attribute_name + ' error : ' + e )
			}
		} else {
			try {
				let response = await ApiService.get('/api/v1/studies?filter[status]=' + keyword)
				let seacrhResultStudies = response.data;
				commit('SET_STUDIES_SEARCH_RESULT', seacrhResultStudies)
			} catch (e) {
				console.log('Search Studies by ' + attribute_name + ' error : ' + e )
			}
		}
    commit('REQUEST_STUDIES', false)
  },

  async searchAllStudies({commit}, keyword){
    commit('REQUEST_STUDIES', true)
		try {
			let response = await ApiService.get('/api/v1/studies?filter[search-all-columns]=' + keyword)
			let searchResultStudies = response.data.data;
			// console.log('HASIL SEARCH: '+JSON.stringify(seacrhResultStudies))
			commit('SET_STUDIES_SEARCH_RESULT', searchResultStudies)
		} catch (e) {
			console.log('Search Studies by all columns error : ' + e )
		}
    commit('REQUEST_STUDIES', false)
  },
  
  async filterStudiesByRIK({commit}, {rik_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_STUDIES', true)
		if(rik_id == '') {
			try {
				let response = await ApiService.get('/api/v1/studies?filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=-createdAt')
				let searchResultStudies = response.data;
				// console.log('HASIL FILTER BY RIK: '+JSON.stringify(searchResultStudies))
				commit('SET_STUDIES_SEARCH_RESULT', searchResultStudies)
			} catch (e) {
				console.log('Filtered Studies By RIK ERROR : ' + e )
			}
		} else {
			try {
				let response = await ApiService.get('/api/v1/riks/'+ rik_id +'/studies?filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
				let searchResultStudies = response.data;
				// console.log('HASIL FILTER BY RIK: '+JSON.stringify(searchResultStudies))
				commit('SET_STUDIES_SEARCH_RESULT', searchResultStudies)
			} catch (e) {
				console.log('Filtered Studies By RIK ERROR : ' + e )
			}
		}
		commit('REQUEST_STUDIES', false)
  },

  async filterStudiesBySector({commit}, {rik_id, sector_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_STUDIES', true)
		try {
			let response = await ApiService.get('/api/v1/riks/' + rik_id + '/studies?filter[sector_id]=' + sector_id + '&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
			let searchResultStudies = response.data;
			// console.log('HASIL FILTER BY SECTOR: '+JSON.stringify(searchResultStudies))
			commit('SET_STUDIES_SEARCH_RESULT', searchResultStudies)
		} catch (e) {
			console.log('Filtered Studies By SECTOR ERROR : ' + e )
		}
		commit('REQUEST_STUDIES', false)
  },

  async filterStudiesByOpd({commit}, {rik_id, sector_id, opd_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_STUDIES', true)
		try {
			let response = await ApiService.get('/api/v1/riks/'+ rik_id +'/studies?filter[sector_id]=' + sector_id + '&filter[opd_id]=' + opd_id + '&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
			let searchResultStudies = response.data;
			// console.log('HASIL FILTER BY OPD: '+JSON.stringify(searchResultStudies))
			commit('SET_STUDIES_SEARCH_RESULT', searchResultStudies)
		} catch (e) {
			console.log('Filtered Studies By OPD ERROR : ' + e )
		}
		commit('REQUEST_STUDIES', false)
  },

  async filterStudiesByThemes({commit}, {rik_id, sector_id, opd_id, theme_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_STUDIES', true)
		try {
			let response = await ApiService.get('/api/v1/riks/'+ rik_id +'/studies?filter[sector_id]=' + sector_id + '&filter[opd_id]=' + opd_id + '&filter[theme_id]=' + theme_id + '&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
			let searchResultStudies = response.data;
			// console.log('HASIL FILTER BY THEME: '+JSON.stringify(searchResultStudies))
			commit('SET_STUDIES_SEARCH_RESULT', searchResultStudies)
		} catch (e) {
			console.log('Filtered Studies By THEME ERROR : ' + e )
		}
		commit('REQUEST_STUDIES', false)
  },

  async submitForm({commit, state}, {formdata, config}){
    commit('REQUEST_STUDIES', true)
    console.log("dari module studies state " + state.isLoading)
    try{
      let response = await ApiService.post('/api/v1/studies', formdata, config)
      // console.log(formdata)
      let submitData = response.data.data;
      let StudyID = response.data.data.id;
      // console.log("Submit Form tulis " +state.isLoading)
      console.log("Submit Success, send to get submit data" + JSON.stringify(submitData));
      commit('SET_FORM_SUBMIT', submitData)
      commit('SET_STUDY_ID', StudyID)
    } catch(e){
      console.log("Module Submit Form Eror nulis "+e)
      // console.log("button clicked but still got error: " + e);
    }
    commit('REQUEST_STUDIES', false)
  },

  async updateStudy({commit, state}, {formdata, config}){
    commit('REQUEST_STUDIES', true)
    console.log("dari module studies state " + state.isLoading)
    const form = JSON.parse(formdata)
    console.log("INI FORM DATA= "+formdata)
    console.log("INI CONFIG= "+config)
    try{
      let response = await ApiService.patch('/api/v1/studies/' + form.data.id, formdata, config)
      let submitData = response.data.data;
      let StudyID = response.data.data.id;
      // console.log("Submit Form tulis " +state.isLoading)
      console.log("Success update hasil penelitian" + JSON.stringify(submitData));
      commit('SET_FORM_SUBMIT', submitData)
      commit('SET_STUDY_ID', StudyID)
    } catch(e){
      console.log("Module Submit Form Eror nulis"+e)
      // console.log("button clicked but still got error: " + e);
    }
    commit('REQUEST_STUDIES', false)
  },

  async uploadFilesStudies({commit}, {formdata, config}){
    commit('REQUEST_STUDIES', true)
    try {
      let response = await ApiService.post('/api/v1/filestudies', formdata, config)
      let uploaded = response.data.data
      commit('SET_UPLOAD_FILE', response.data);
      console.log('UPLOAD SUCCESS')
      console.log('FILE TERUPLOAD: '+JSON.stringify(uploaded))
      commit('REQUEST_STUDIES')
    } catch (e) {
    console.log('FetchAllStudies : ' + e)
    }
    commit('REQUEST_STUDIES', false)
  },
  
  async updateFilesStudies({commit}, {formdata, config}){
    commit('REQUEST_STUDIES', true)
    console
    try {
      let response = await ApiService.post('/api/v1/filestudies', formdata, config)
      let uploaded = response.data.data
      commit('SET_UPLOAD_FILE', response.data);
      console.log('UPLOAD SUCCESS')
      console.log('FILE TERUPLOAD: '+JSON.stringify(uploaded))
      commit('REQUEST_STUDIES')
    } catch (e) {
    console.log('FetchAllStudies : ' + e)
    }
    commit('REQUEST_STUDIES', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}