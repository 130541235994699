import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  error: {
    errorMessage: '',
    errorCode: 0
  },
  themes: {
    data: []
  },
  themesBySector: [],
  themesByOpd: [],
  userOpd: '',
  paginateThemes: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {},
    included: {}
  },
  theme: {}
};

const getters = {
  getThemes: (state) => {
    return state.themes
  },

  getPaginateThemes: (state) => {
    return state.paginateThemes
  },

  getTheme: (state) => {
    return state.theme
  },

  getTotalThemes: (state) => {
    return state.themes.length
  },

  getThemesBySector: (state) => {
    return state.themesBySector
  },
  getThemesByOpd: (state) => {
    return state.themesByOpd
  },
  getError: (state) => {
    return state.error
  }
};

const mutations = {
  REQUEST_THEMES(state, status) {
    state.isLoading = status
  },

  SET_THEMES(state, response) {
    state.themes = response.data
  },

  SET_THEME(state, theme) {
    state.theme = theme
  },

  SET_PAGINATE_THEMES(state, response) {
    state.paginateThemes = response
  },

  SET_THEMESBYSECTOR(state, sector) {
    state.themesBySector = sector
  },
  
  SET_THEMES_BY_OPD(state, opd) {
    state.themesByOpd = opd
  },

  CLEAR_THEMES_BY_OPD(state) {
    state.themesByOpd = []
  },

  SET_ERROR(state, {errorMessage, errorCode}){
    state.error.errorMessage = errorMessage
    state.error.errorCode = errorCode
  }
};

const actions = {
  async createTheme({commit}, {data, config}){
    commit('REQUEST_THEMES', true)
    try {
      let response = await ApiService.post('/api/v1/themes', data, config)
      commit('SET_THEME', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_THEMES', false)
  },

  async updateTheme({commit}, {data, config}){
    commit('REQUEST_THEMES', true)
    const form = JSON.parse(data)
    try {
      let response = await ApiService.patch('/api/v1/themes/' + form.data.id, data, config)
      commit('SET_THEME', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_THEMES', false)
  },

  async fetchAllThemes({commit}) {
    commit('REQUEST_THEMES', true)

    try {
      let response = await ApiService.get('/api/v1/themes')
      let total = response.data.meta.page.total
      let totalResponse = await ApiService.get('/api/v1/themes?page[number]=1&page[size]=' + total )
      commit('SET_THEMES', totalResponse)
    } catch (e) {
      console.log('FetchAllThemes : ' + e)
    }
    commit('REQUEST_THEMES', false)
  },

  async fetchPaginateThemes({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_THEMES', true)
    if(sortType=='desc'){
      sortName = '-' + sortName
    }
    try {
      let response = await ApiService.get('/api/v1/themes?include=sector&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
      commit('SET_PAGINATE_THEMES', response.data)
    } catch (e) {
      console.log('FetchAllThemes : ' + e)
    }
    commit('REQUEST_THEMES', false)
  },

  async fetchThemes({commit}, sector_id) {
    commit('REQUEST_THEMES', true)
    try {
      let response = await ApiService.get('/api/v1/themes?filter[sector_id]=' + sector_id)
      let total = response.data.meta.page.total
      let totalResponse = await ApiService.get('/api/v1/themes?filter[sector_id]=' + sector_id + '&page[number]=1&page[size]=' + total )
      commit('SET_THEMESBYSECTOR', totalResponse.data.data)
    } catch (e) {
      console.log('Fetch Themes by SECTOR ID got error : ' + e)
    }
    commit('REQUEST_THEMES', false)
  },

  async fetchThemesBySector({commit}, sector_id) {
    commit('REQUEST_THEMES', true)
    let userOpd= JSON.parse(localStorage.getItem('me')).opd_id
    try {
      let response = await ApiService.get('/api/v1/opds/' + userOpd + '/themes?filter[sector_id]=' + sector_id)
      let themes = response.data.data;
      console.log(themes)
      commit('SET_THEMESBYSECTOR', themes)
    } catch (e) {
      console.log('Fetch Themes by SECTOR ID got error : ' + e)
    }
    commit('REQUEST_THEMES', false)
  },

  async fetchThemesWithoutSector({ commit }, search = '') {
    commit('REQUEST_THEMES', true)
    let userOpd = JSON.parse(localStorage.getItem('me')).opd_id
    try {
      let response = await ApiService.get('/api/v1/opds/' + userOpd + '/themes?filter[search-name]=' + search)
      let themes = response.data.data;
      console.log(themes)
      commit('SET_THEMESBYSECTOR', themes)
    } catch (e) {
      console.log('Fetch Themes by SECTOR ID got error : ' + e)
    }
    commit('REQUEST_THEMES', false)
  },

  async fetchThemesByOpds({commit}, opd_id) {
    commit('REQUEST_THEMES', true)

    try {
      let response = await ApiService.get('/api/v1/opds/' + opd_id + '/themes')
      let themes = response.data.data;
      commit('SET_THEMES_BY_OPD', themes)
    } catch (e) {
      console.log('Fetch Themes by SECTOR ID got error : ' + e)
    }
    commit('REQUEST_THEMES', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}