<template>	
	<main class="flex-1 overflow-x-hidden overflow-y-auto">
		<div class="container mx-auto px-6 pb-8">
			<h3 class="text-gray-700 text-3xl font-medium">Data Hasil Kelitbangan</h3>
			<div class="flex justify-end">
				<input
					class="rounded-lg py-2 mr-2"
					type="number" min="2000" max="9999"
					placeholder="Tahun..."
					v-model="yearExport"
				/>
				<button 
					type="button"
					class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg"
					@click="exportData()">
					Export Data
				</button>
			</div>
			<div class="mt-8">
				<form @submit.prevent="searchData">
					<div class="container flex flex-col justify-between items-center relative">
							<div class="shadow flex w-full relative">
							
								<input
									class="w-full rounded-lg py-2"
									type="text"
									placeholder="Cari Kelitbangan..."
									v-model="search.keyword"
								/>
								<div class="absolute inset-y-0 right-0 flex items-center">
									<label for="currency" class="sr-only">Filter</label>
									<select v-model="search.attribute_name"  class="h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
										<option value="search-all-columns" selected>All</option>
										<option value="search-title">Title</option>
										<option value="search-team">Team</option>
										<option value="search-contact">Contact</option>
										<option value="search-problem">Problem</option>
										<option value="search-background">Background</option>
										<option value="search-method">Method</option>
										<option value="search-result">Result</option>
										<option value="status">Status</option>
									</select>
								</div>
							</div>
							
					</div>
					</form>
			</div>
			<div class="flex flex-col mt-4">				
				<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div
						class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table class="min-w-full">
							<thead>
								<tr>
									<th
										class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-1/12">
										No</th>
									<th
										@click="sort('title')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12 hover:bg-gray-100">
                      <div class="flex items-center justify-between">
                        <p>Judul</p>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                        </svg>
                      </div>
                  </th>
									<th
										@click="sort('background')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-4/12 hover:bg-gray-100">
										Ringkasan</th>
									<th
										@click="sort('team')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12 hover:bg-gray-100">
										Tim</th>
									<th
										@click="sort('status')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-1/12 hover:bg-gray-100">
										Status</th>									
									<th
										class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-1/12">
										Aksi</th>
								</tr>
							</thead>
							<tbody class="bg-white">
								<tr v-for="(study, index) in getStudies.data" :value="study.id" :key="study.id">
									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{(getStudies.meta.page.currentPage * getStudies.meta.page.perPage) - getStudies.meta.page.perPage  + index + 1}}
										</div>
									</td>
									
									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{study.attributes.title}}
										</div>
									</td>

									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{study.attributes.background | truncate(250)}}
										</div>
									</td>

									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{study.attributes.team}}
										</div>
									</td>

									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											<div :class="[(study.attributes.status === 'approved' ? 'status-approved' : ''), (study.attributes.status === 'banned' ? 'status-rejected' : ''), (study.attributes.status === 'submitted' ? 'status-submited' : '')]">
												{{ study.attributes.status }}
											</div>
										</div>
									</td>

									<!-- <td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											<a href="#" class="font-medium text-blue-600 hover:text-blue-500">
												Download
											</a>
										</div>
									</td> -->

									<td
										class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium">
										<div class="flex items-center">
                      <router-link
                      :to="'/admin/hasil-kelitbangan/detailkelitbangan/'+study.id" class="p-3 text-indigo-600 hover:text-indigo-900">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M9.99996 5.99998C9.99996 7.10455 9.10453 7.99998 7.99996 7.99998C6.89539 7.99998 5.99996 7.10455 5.99996 5.99998C5.99996 4.89541 6.89539 3.99998 7.99996 3.99998C9.10453 3.99998 9.99996 4.89541 9.99996 5.99998Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M1.63879 5.99996C2.48831 3.29523 5.01517 1.33331 8.00025 1.33331C10.9854 1.33331 13.5122 3.29526 14.3617 6C13.5122 8.70473 10.9854 10.6666 8.00027 10.6666C5.01516 10.6666 2.4883 8.70471 1.63879 5.99996Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
                      </router-link>
											<!-- <button @click="detailData(study)" class="p-3 text-indigo-600 hover:text-indigo-900">
												<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M9.99996 5.99998C9.99996 7.10455 9.10453 7.99998 7.99996 7.99998C6.89539 7.99998 5.99996 7.10455 5.99996 5.99998C5.99996 4.89541 6.89539 3.99998 7.99996 3.99998C9.10453 3.99998 9.99996 4.89541 9.99996 5.99998Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M1.63879 5.99996C2.48831 3.29523 5.01517 1.33331 8.00025 1.33331C10.9854 1.33331 13.5122 3.29526 14.3617 6C13.5122 8.70473 10.9854 10.6666 8.00027 10.6666C5.01516 10.6666 2.4883 8.70471 1.63879 5.99996Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</button> -->
											<button @click="editData(study)" class="p-3 text-indigo-600 hover:text-indigo-900">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.3333 9.77335V13.3334C13.3333 13.687 13.1928 14.0261 12.9427 14.2762C12.6927 14.5262 12.3535 14.6667 11.9999 14.6667H2.66659C2.31296 14.6667 1.97382 14.5262 1.72378 14.2762C1.47373 14.0261 1.33325 13.687 1.33325 13.3334V4.00002C1.33325 3.6464 1.47373 3.30726 1.72378 3.05721C1.97382 2.80716 2.31296 2.66669 2.66659 2.66669H6.22659" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M11.9999 1.33331L14.6666 3.99998L7.99992 10.6666H5.33325V7.99998L11.9999 1.33331Z" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</button>
											<button @click="deleteData(study.id)" class="p-3 text-indigo-600 hover:text-indigo-900">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M2 4H3.33333H14" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M5.33325 3.99998V2.66665C5.33325 2.31302 5.47373 1.97389 5.72378 1.72384C5.97383 1.47379 6.31296 1.33331 6.66659 1.33331H9.33325C9.68687 1.33331 10.026 1.47379 10.2761 1.72384C10.5261 1.97389 10.6666 2.31302 10.6666 2.66665V3.99998M12.6666 3.99998V13.3333C12.6666 13.6869 12.5261 14.0261 12.2761 14.2761C12.026 14.5262 11.6869 14.6666 11.3333 14.6666H4.66659C4.31296 14.6666 3.97382 14.5262 3.72378 14.2761C3.47373 14.0261 3.33325 13.6869 3.33325 13.3333V3.99998H12.6666Z" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M6.66675 7.33331V11.3333" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M9.33325 7.33331V11.3333" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</button>
										</div>
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
					<Paginate 
            :page-count="getStudies.meta.page.lastPage"
            :page-range="2"
						:click-handler="paginateStudies"
            :prev-text="'Prev'"
            :prevClass="'-mt-px w-0 flex-1 flex'"
            :prev-link-class="'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
            :next-text="'Next'"
            :nextClass="'-mt-px w-0 flex-1 flex justify-end'"
            :next-link-class="'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
            :page-class="'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
            :activeClass="'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
            :container-class="'border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'">
          </Paginate>
				</div>
			</div>

		</div>

    <pop-up-transition>
        <confirm-delete
        v-show="isConfirmDeleteModalVisible"
        modalHeadline='Hapus Data'
        deleteMessage="Apakah anda yakin?"
        @deleteRecordEvent="deleteRecords"
        @close="closeConfirmDeleteModal"
      ></confirm-delete>
    </pop-up-transition>
    
    <pop-up-transition>
      <div v-if="openEditData" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex bg-gray-400 bg-opacity-50">
        <div class="relative w-2/3 my-6 mx-auto max-w-xl">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
          <form v-on:submit.prevent="updateData">
            <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <h3 class="text-xl font-semibold">
                Update Status
              </h3>
              <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="createData()">
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            
            <!--body-->
            <div class="relative p-6 flex-auto">
              <div class="mb-4">
                <div class="grid grid-cols-3 gap-4 align-middle">
                  <label for="status" class="flex items-center text-md text-gray-600 dark:text-gray-400">Status</label>
                  <select v-model="dataStudies.status" class="col-span-2 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" required>
                    <option value="" selected disabled>Pilih Status</option>
                    <option value="submitted">Submitted</option>
                    <option value="approved">Approved</option>
                    <option value="banned">Banned</option>
                  </select>
                </div>
              </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
              <button 
                type="button"
                class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2"
                @click="toggleEditData()">
                Batal
              </button>
              <button 
                type="submit"
                class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg">
                Perbaharui Data
              </button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </pop-up-transition>

    <pop-up-transition>
      <div v-if="openDetailData" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex bg-gray-400 bg-opacity-50">
          <div class="relative w-2/3 my-6 mx-auto max-w-xl">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <!--header-->
            <form v-on:submit.prevent="editData">
              <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 class="text-xl font-semibold">
                  Detail Hasil kelitbangan
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="createData()">
                  <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>						
              <!--body-->
              <div class="relative p-6 flex-auto">
                <div class="mb-4">
                  <strong for="name" class="block text-base text-gray-600 dark:text-gray-400">Nama</strong>
                  <p class="pt-2 pl-4">{{dataStudy.title}}</p>
                </div>
                <div class="mb-4">
                  <strong for="name" class="block text-base text-gray-600 dark:text-gray-400">Deskripsi</strong>
                  <p class="pt-2 pl-4">{{dataStudy.abstract}}</p>
                </div>
              </div>
              <!--footer-->
              <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                <button 
                  type="button"
                  class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2"
                  @click="toggleDetailData()">
                  Tutup
                </button>
              </div>
            </form>
            </div>
          </div>
      </div>
    </pop-up-transition>
	</main>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import Paginate from 'vuejs-paginate';
import PopUpTransition from '../../../components/PopUpTransition.vue';
import ConfirmDelete from "@/components/ConfirmDelete";
export default {
  name: "adminHasilKelitbangan",
  components: {
    Paginate,
    PopUpTransition,
    ConfirmDelete
  },
	data() {
		return {
			openCreateData: false,
			openEditData: false,
			openDetailData: false,
      isConfirmDeleteModalVisible: false,
			currentSort: 'updatedAt',
			currentSortType: 'desc',
			yearExport: '',
			search: {
				attribute_name: 'search-all-columns',
				keyword: ''
			},
			dataStudies: {
				id: '',
				status: '',
				relationships: {
					user: '',
					theme: '',
					opd: '',
					sector: ''
				}
			}
		}
	},
	created() {
		this.fetchAllStudies({
      attribute_name: this.search.attribute_name,
      keyword: this.search.keyword,
      pageNumber: 1,
      pageSize: 10,
			sortName: this.currentSort,
			sortType: this.currentSortType
    })
	},
	computed: {
		...mapGetters({
			getStudies: 'studies/getStudies',
		})
	},
	methods: {
		...mapActions({
			fetchAllStudies: 'studies/fetchAllStudies',
			updateStudy: 'studies/updateStudy',
			exportStudies: 'studies/exportStudies'
		}),
		toggleEditData() {
			this.openEditData = !this.openEditData;
			this.resetData()
		},
		toggleDetailData() {
			this.openDetailData = !this.openDetailData;
		},
    resetData(){
			this.dataStudies = {
				status: ''
			}
		},
		detailData(study) {
			this.openDetailData = !this.openDetailData;
			this.dataStudy = {
				id: study.id,
				title: study.attributes.title,
				abstract: study.attributes.abstract,
			}
		},
		editData(study) {
			this.openEditData = !this.openEditData;
			this.dataStudies = {
				id: study.id,
				status: study.attributes.status,
				relationships: {
					user: study.relationships.user.data.id,
					theme: study.relationships.theme.data.id,
					opd: study.relationships.opd.data.id,
					sector: study.relationships.sector.data.id
				}
			}
		},
		updateData(){
			let token = localStorage.getItem("token")
			
			const config = {
				'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
			};
			console.log(this.dataStudies.relationships)

			const raw = JSON.stringify({
				data: {
					type: "studies",
					id: this.dataStudies.id,
					attributes: {
						status: this.dataStudies.status
					},
					relationships: {
						user: {
							data: {
								type: "users",
								id: this.dataStudies.relationships.user
							}
						},
						theme: {
							data: {
								type: "themes",
								id: this.dataStudies.relationships.theme
							}
						},
						opd: {
							data: {
								type: "opds",
								id: this.dataStudies.relationships.opd
							}
						},
						sector: {
							data: {
								type: "sectors",
								id: this.dataStudies.relationships.sector
							}
						}
					}
				}
			})
			this.updateStudy({
				formdata: raw,
				config: config
			}).then(() => {
				this.openEditData = !this.openEditData
				this.resetData()
				this.fetchAllStudies({
					attribute_name: this.search.attribute_name,
					keyword: this.search.keyword,
					pageNumber: 1,
					pageSize: 10,
					sortName: this.currentSort,
					sortType: this.currentSortType
				})
			})
		},
		deleteData(id){
			this.isConfirmDeleteModalVisible = true;
      console.log(id)
		},
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    deleteRecords() {
      console.log("goodbye");
    },
		searchData(){
			this.fetchAllStudies({
				attribute_name: this.search.attribute_name,
				keyword: this.search.keyword,
				pageNumber: 1,
				pageSize: 10,
				sortName: this.currentSort,
				sortType: this.currentSortType
			})
		},
		sort(sortName){
			if(sortName === this.currentSort){
				this.currentSortType = this.currentSortType === 'asc' ? 'desc' : 'asc'
				this.fetchAllStudies({
					attribute_name: this.search.attribute_name,
					keyword: this.search.keyword,
					pageNumber: 1,
					pageSize: 10,
					sortName: this.currentSort,
					sortType: this.currentSortType
				})
			} else {
				this.currentSort = sortName
				this.currentSortType = 'asc'
				this.fetchAllStudies({
					attribute_name: this.search.attribute_name,
					keyword: this.search.keyword,
					pageNumber: 1,
					pageSize: 10,
					sortName: this.currentSort,
					sortType: this.currentSortType
				})
			}
		},
		paginateStudies(pageNumber){
			this.fetchAllStudies({
				attribute_name: this.search.attribute_name,
				keyword: this.search.keyword,
				pageNumber: pageNumber,
				pageSize: 10,
				sortName: this.currentSort,
				sortType: this.currentSortType
			})
		},
		exportData(){
			console.log(this.yearExport)
			let token = localStorage.getItem("token")
			console.log(token)
			const config = {
				'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': `Bearer ${token}`,
			};
			console.log(config)
			this.exportStudies({
				year: this.yearExport,
				config: config
			})
		}
	}
};
</script>

<style lang="postcss">
.status-draft{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800 md:mt-2 lg:mt-0;
},

.status-approved{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0;
},

.status-submited{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800 md:mt-2 lg:mt-0;
}

.status-rejected{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0;
}
</style>