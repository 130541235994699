import axios from 'axios'
import { StorageService } from '@/services/storage.service'

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${StorageService.getToken()}`
        axios.defaults.headers.post['Content-Type'] = 'application/vnd.api+json';
        axios.defaults.headers.patch['Content-Type'] = 'application/vnd.api+json';
        axios.defaults.headers.get['Content-Type'] = 'application/vnd.api+json';
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource) {
        return axios.get(resource)
    },

    post(resource, data, config) {
        return axios.post(resource, data, { headers: config })
    },

    patch(resource, data, config) {
        return axios.patch(resource, data, {headers: config})
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        return axios(data)
    }
}

export default ApiService