<template>
  <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
    <form class="my-10 space-y-8 divide-y divide-gray-200" >
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Edit Kelitbangan
          </h3>
          <p class="mt-1 text-sm text-gray-500">Ubah Kelitbangan</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4 mt-4">
            <label for="opd" class="block text-sm font-medium text-gray-700">
              RIK
            </label>
            <div class="mt-1">
              <select
                v-model="detailRIK.id"
                @change="changeRIK"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" disabled >Pilih RIK</option>
                <option
                  v-for="rik in getRiks"
                  :value="rik.id"
                  :key="rik.id"
                >
                  {{ rik.attributes.startYear }} - {{ rik.attributes.endYear }}
                </option>
              </select>              
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="judul" class="block text-sm font-medium text-gray-700">
              Bidang
            </label>
            <div class="mt-1">
              <select
                id="bidang"
                v-model="getSectorID.id"
                @click="getSector"
                @change="changeSector"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" disabled >Pilih Bidang</option>
                <option v-if="getSectorByOpd.length==0" :value="getSectorID.id" selected data-default>{{getSectorID.attributes.name}}</option>
                <option
                  v-for="bidang in getSectorByOpd"
                  :value="bidang.id"
                  :key="bidang.id"
                >
                  {{ bidang.attributes.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="tema" class="block text-sm font-medium text-gray-700">
              Tema
            </label>
            <div class="mt-1">
              <select
                id="tema"
                v-model="getThemesID.id"
                @click="getThemes"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" disabled >Pilih Bidang</option>
                <option v-if="getThemesBySector.length==0" :value="getThemesID.id">{{getThemesID.attributes.name}}</option>
                <option
                  v-for="tema in getThemesBySector"
                  :value="tema.id"
                  :key="tema.id"
                >
                  {{ tema.attributes.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="judul" class="block text-sm font-medium text-gray-700">
              Judul Kelitbangan
            </label>
            <div class="mt-1">
              <input
                id="judulpenelitian"
                v-model="detail.data.data.attributes.title"
                type="text"
                required
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="penyusun" class="block text-sm font-medium text-gray-700">
              Penyusun
            </label>
            <div class="mt-1">
              <input
                id="penyusun"
                v-model="detail.data.data.attributes.team"
                type="text"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
								
              />
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="contact" class="block text-sm font-medium text-gray-700">
              Kontak
            </label>
            <div class="mt-1">
              <input
                id="contact"
                v-model="detail.data.data.attributes.contact"
                type="text"
                value="Biro Pemerintahan"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label
              for="permasalahan"
              class="block text-sm font-medium text-gray-700"
            >
              Permasalahan pokok yang dibahas dalam kelitbangan
            </label>
            <div class="mt-1">
              <textarea
                id="permasalahan"
                v-model="detail.data.data.attributes.problem"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="latarbelakang"
              class="block text-sm font-medium text-gray-700"
            >
              Alasan atau latar belakang dilakukannya kelitbangan
            </label>
            <div class="mt-1">
              <textarea
                id="latarbelakang"
                v-model="detail.data.data.attributes.background"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat 
            </p>
          </div>

          <div class="sm:col-span-6">
            <label for="metode" class="block text-sm font-medium text-gray-700">
              Bagaimana kelitbangan dilakukan dan metode yang digunakan dalam
              kelitbangan
            </label>
            <div class="mt-1">
              <textarea
                id="metode"
                v-model="detail.data.data.attributes.method"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="prospek"
              class="block text-sm font-medium text-gray-700"
            >
              Pernyataan singkat tentang kegiatan yang telah dilakukan (hasil)
              serta prospeknya
            </label>
            <div class="mt-1">
              <textarea
                id="prospek"
                v-model="detail.data.data.attributes.result"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-4">
            <label
              for="katakunci"
              class="block text-sm font-medium text-gray-700"
            >
              Kata kunci
            </label>
            <div class="mt-1">
              <input
                id="katakunci"
                v-model="detail.data.data.attributes.keyword"
                type="text"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <p class="mt-2 text-xs italic text-gray-500">3 sampai 5 kata</p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="cover_photo"
              class="block text-sm font-medium text-gray-700"
            >
              Lampiran
            </label>
            <div class="sm:col-span-3">
              <dd class="mt-1 text-sm text-gray-900" v-for="dataFile in getDetail.included" :key="dataFile.id">
                <ul class="border border-gray-200 rounded-md divide-y divide-gray-200 mt-2" v-if="dataFile.type == 'filestudies'">
                  <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div class="w-0 flex-1 flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        x-description="Heroicon name: solid/paper-clip"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span class="ml-2 flex-1 w-0 truncate">
                        {{dataFile.attributes.name}}
                      </span>
                    </div>
                    <div class="ml-4 flex-shrink-0">
                      <a
                        :href="`/` + `${dataFile.attributes.url}`"
                        target="_blank" 
                        class="font-medium text-blue-600 hover:text-blue-500"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </div>

          <div class="sm:col-span-6">
            <label for="cover_photo" class="block text-sm font-medium text-gray-700">Unggah Documents</label>
            <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div class="space-y-1 text-center">
                <div class="text-sm text-gray-600">
                  <label
                    for="file-upload"
                    class="hover:bg-indigo-50 p-2 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Unggah</span>
                    <input
                      id="file-upload"
                      type="file"
                      @change="handleFileUpload"
                      class="sr-only"
                    />
                  </label>
                </div>
                <p v-if="uploadedfiles!=null" class="mt-2 text-xs text-gray-500">{{uploadedfiles.name}}</p>
                <p class="mt-2 text-xs text-gray-500">Format yang didukung PDF</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
            type="button"
            @click="uploadfiles"
            class="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Simpan sebagai Draft
          </button>
          <button
            type="button"
            @click="submit"
            class="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Simpan
          </button>
          <button
            type="button"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>

    <!-- {{getDetail.included}} -->
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import axios from "axios";

export default {
  name: "EditPenelitian",

  data() {
    return {
			studies_id: this.$route.params.studies_id,
      form: {
        rik: "",
        bidang: "",
        tema: "",
        judulpenelitian: "",
        opdpengampu: "",
        contact: "",
        penyusun: "",
        permasalahan: "",
        latarbelakang: "",
        metode: "",
        prospek: "",
        tag: "",
        keyword: "",
      },
      uploadedfiles: null
    };
  },

  computed:{

    ...mapGetters({
      getSectorByOpd: 'opds/getSector',
      getUser: 'opds/getUser',
      getName: 'opds/getName',
      getOpd: 'opds/getOpd',
      getRiks: 'riks/getRiks',
      getThemesBySector: 'themes/getThemesBySector',
      getThemesByOpd: 'themes/getThemesByOpd',
      getSubmitData: 'studies/getSubmitData',
      getCLoading: 'studies/getLoading',
			getDetail: 'studies/getDetailStudy',
      getThemesID: 'studies/getThemesID',
      getSectorID: 'studies/getSectorID',
    }),

    ...mapState({
      isCLoading: state => state.studies.isLoading,
      study_id: state => state.studies.study_id,
      detailRIK: state => state.studies.detailsRIK,
      detail: state=> state.studies.details
    }),
    
  },

  methods: {
    ...mapActions({
      fetchAllSectors: 'sectors/fetchAllSectors',
      fetchAllRiks: 'riks/fetchAllRiks',
      fetchSectorsByOpd: 'opds/fetchSectorsByOpd',
      fetchThemesBySector: 'themes/fetchThemesBySector',
      fetchThemesByOpds: 'themes/fetchThemesByOpds',
      updateStudy: 'studies/updateStudy',
      uploadFilesStudies: 'studies/uploadFilesStudies',
			StudyDetails: 'studies/StudyDetails',
      fetchSectorByID: 'sectors/fetchSectorByID',
      updateFilesStudies: 'studies/updateFilesStudies'
      // fetchAllOpdId: 'opds/fetchOpdByID'
    }),

    handleFileUpload(event) {
      this.uploadedfiles = event.target.files[0];
    },

    async submit(){
      let token = localStorage.getItem("token");
      let user = JSON.parse(localStorage.getItem('me')).uuid
      let opd = JSON.parse(localStorage.getItem('me')).opd_id

      const config = {        
        'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
      };

      const raw = JSON.stringify({
        "data": {
          "type": "studies",
          "id": this.$route.params.studies_id,
          "attributes": {
            "title": this.detail.data.data.attributes.title,
            "team": this.detail.data.data.attributes.team,
            "contact": this.detail.data.data.attributes.contact,
            "problem": this.detail.data.data.attributes.problem,
            "background": this.detail.data.data.attributes.background,
            "method": this.detail.data.data.attributes.method,
            "result": this.detail.data.data.attributes.result,
            "keyword": this.detail.data.data.attributes.keyword,
            // "status": "submitted"
          },
          "relationships": {
            "user": {
              "data": {
                "type": "users",
                "id": user
              }
                
            },
            "theme": {
              "data": {
                "type": "themes",
                "id": this.getThemesID.id
              }
                
            },
            "opd": {
              "data": {
                "type": "opds",
                "id": opd
              } 
            },
            "sector": {
              "data": {
                "type": "sectors",
                "id": this.getSectorID.id
              }
                
            }
        }
        }
      });
      await this.updateStudy({
        formdata: raw,
        config: config
      })

      if(this.uploadedfiles == null){
        console.log("tanpa file")
        this.$router.push('/user/detailkelitbangan/'+this.studies_id)
      }
      else {
        console.log("dengan file")
        this.uploadfiles(this.getSubmitData.id)
        this.$router.push('/user/detailkelitbangan/'+this.studies_id)
      }

    },

    uploadfiles(study_id){
      console.log("CLICKED UPLOAD BUTTON")
      console.log(study_id)

      let token = localStorage.getItem("token");
      var data = new FormData();
      data.append('file', this.uploadedfiles);
      data.append('study_id', study_id);

      const config = {        
        'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      };

      this.updateFilesStudies({
        formdata: data,
        config: config})
    },

    changeRIK() {
      this.fetchSectorsByOpd(this.detailRIK.id);
    },
    changeSector() {
      this.fetchThemesBySector(this.getSectorID.id)
    },

    getSector() {
      if(this.getSectorID.id.length == 0){
        this.fetchSectorsByOpd(this.detailRIK.id);
      }
    },

		getThemes() {
      if(this.getThemesBySector.length==0){
        this.fetchThemesBySector(this.getSectorID.id)
      }
		},
    changeTheme(event){
      this.getThemesID.id = event.target.value
    }
  },

  created() {
		this.StudyDetails(this.studies_id);
    this.fetchAllRiks();
  }
};
</script>