<template>
  <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
    <div class="bg-white mt-8 lg:border lg:border-gray-200 rounded-lg">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <img
                class="hidden h-16 w-16 rounded-full sm:block"
                :src="getUser.attributes.imageProfile.url" 
                @error="$event.target.src = require(`@/assets/avatar-placeholder.png`)"
                alt=""
              />
              <div>
                <div class="flex-col sm:flex sm:items-start">
                  <img
                    class="h-16 w-16 rounded-full sm:hidden"
                    :src="getUser.attributes.imageProfile.url" 
                    @error="$event.target.src = require(`@/assets/avatar-placeholder.png`)"
                    alt=""
                  />
                  <h1 class="font-bold leading-7 text-gray-900  sm:ml-3 sm:text-2xl sm:leading-9 sm:truncate">
                    Selamat Datang, {{getUser.attributes.name}}
                  </h1>
                  <!-- MOBILE -->
                  <div
                    class="flex items-center text-sm text-gray-500 font-medium capitalize sm:hidden"
                  >
                    <svg
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      x-description="Heroicon name: solid/office-building"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {{getUserOpd.attributes.name}}
                  </div>
                </div>
                <dl
                  class="hidden mt-6 flex flex-col sm:block sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap"
                >
                  <dt class="sr-only">Company</dt>
                  <dd
                    class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6"
                  >
                    <svg
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      x-description="Heroicon name: solid/office-building"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {{getUserOpd.attributes.name}}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-6 sm:px-0 md:mt-8">
      <div class="hidden sm:block">
        <div class="">
          <h2 class="text-lg leading-6 font-medium text-gray-900">Ringkasan</h2>
          <div
            class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2"
            x-max="1"
          >
            <!-- Card -->

            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="p-5">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-6 w-6 text-gray-400"
                      x-description="Heroicon name: outline/scale"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      ></path>
                    </svg>
                  </div>
                  <div class="ml-5 w-0 flex-1">
                    <dl>
                      <dt class="text-sm font-medium text-gray-500 truncate">
                        Total Hasil Kelitbangan
                      </dt>
                      <dd>
                        <div class="text-2xl font-medium text-gray-900">{{getTotalStudies}}</div>
                      </dd>
                    </dl>
                  </div>
                  <router-link to="/user/tambahkelitbangan">
                    <button class="bg-blue-500 hover:bg-blue-400 rounded-md p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="bg-blue-50 px-5 py-3">
                <div class="text-sm">
                  <router-link
                    to="/user/daftarkelitbangan"
                    class="font-medium text-cyan-700 hover:text-cyan-900"
                  >
                    View all
                  </router-link>
                </div>
              </div>
            </div>

            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="p-5">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-6 w-6 text-gray-400"
                      x-description="Heroicon name: outline/check-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div class="ml-5 w-0 flex-1">
                    <dl>
                      <dt class="text-sm font-medium text-gray-500 truncate">
                        Total Usulan
                      </dt>
                      <dd>
                        <div class="text-2xl font-medium text-gray-900">{{getTotalProposals}}</div>
                      </dd>
                    </dl>
                  </div>
                  <router-link to="/user/tambahusulan">
                    <button class="bg-blue-500 hover:bg-blue-400 rounded-md p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="bg-yellow-50 px-5 py-3">
                <div class="text-sm">
                  <a
                    href="/user/hasilusulan"
                    class="font-medium text-cyan-700 hover:text-cyan-900"
                  >
                    View all
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MOBILE -->
      <div class="sm:hidden">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            Ringkasan
        </h3>
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
                Total Hasil Kelitbangan
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                {{getTotalStudies}}
            </dd>
            </div>

            <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
                Total Usulan
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                {{getTotalProposals}}
            </dd>
            </div>
        </dl>
      </div>
    </div>


    <div class="mt-4">
      <div class="py-4 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-800">
          Hasil Kelitbangan
        </h3>
        <p class="text-sm leading-6 font-light text-gray-600">
          Menampilkan hasil kelitbangan terbaru
        </p>
      </div>
      <div class="mt-4 px-2 sm:px-0" v-for="studies in getStudiesByUser" :value="studies.id" :key="studies.id">
        <ul class="space-y-4">
          <li class="bg-white px-4 py-6 shadow sm:p-6 rounded-lg border">
            <article aria-labelledby="list-user-studies">
              <div>
                <div class="flex justify-between items-baseline md:block lg:flex">
                  <router-link
                    :to="'/user/detailkelitbangan/'+studies.id"
                    class="text-base font-medium text-gray-900 hover:text-indigo-700"
                  >
                    <span class="flex items-baseline text-2xl">
                      {{ studies.attributes.title }}
                    </span>
                  </router-link>
                  <div class="flex justify-between items-baseline md:block lg:flex">
                    <div :class="[(studies.attributes.status === 'draft' ? 'status-draft' : ''), (studies.attributes.status === 'rejected' ? 'status-rejected' : ''), (studies.attributes.status === 'submitted' ? 'status-submited' : ''), (studies.attributes.status === 'approved' ? 'status-approved' : '')]">
                      {{ studies.attributes.status }}
                    </div>
                  </div>
                </div>
                <h2 class="text-base font-medium text-gray-900 text-xs mt-2">
                <span class="text-sm font-normal">Disusun Oleh:</span> {{studies.attributes.team}}</h2>
              </div>
              <div class="mt-2 text-base text-gray-700 overflow-ellipsis overflow-hidden">
                <p>
                  {{studies.attributes.background}}
                </p>
              </div>
            </article>
          </li>
        </ul>
      </div>
    </div>

    <div class="my-4">
      <div class="py-4 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-800">
          Usulan
        </h3>
        <p class="text-sm leading-6 font-light text-gray-600">
          Menampilkan usulan terbaru
        </p>
      </div>
      <div class="mt-4 px-2 sm:px-0" v-for="proposals in getProposalsByUser" :value="proposals.id" :key="proposals.id">
        <ul class="space-y-4">
          <li class="bg-white px-4 py-6 shadow sm:p-6 rounded-lg border">
            <article aria-labelledby="list-user-studies">
              <div>
                <div class="flex justify-between items-baseline md:block lg:flex">
                  <router-link
                    :to="'/user/detailusulan/'+proposals.id"
                    class="text-base font-medium text-gray-900 hover:text-indigo-700"
                  >
                    <span class="flex items-baseline text-2xl">
                      {{ proposals.attributes.title }}
                    </span>
                  </router-link>
                  <div class="flex justify-between items-baseline md:block lg:flex">
                    <div :class="[(proposals.attributes.status === 'draft' ? 'status-draft' : ''), (proposals.attributes.status === 'rejected' ? 'status-rejected' : ''), (proposals.attributes.status === 'submitted' ? 'status-submited' : ''), (proposals.attributes.status === 'approved' ? 'status-approved' : '')]">
                      {{ proposals.attributes.status }}
                    </div>
                  </div>
                </div>
                <h2 class="text-base font-medium text-gray-900 text-xs mt-2">
                <span class="text-sm font-normal">Disusun Oleh:</span> {{proposals.attributes.team}}</h2>
              </div>
              <div class="mt-2 text-base text-gray-700 overflow-ellipsis overflow-hidden">
                <p>
                  {{proposals.attributes.background}}
                </p>
              </div>
            </article>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="mt-8 px-2 sm:px-0" v-for="data in getProposalsByUser" :value="data.id" :key="data.id">
      <ul class="space-y-4">
        <li class="bg-white px-4 py-6 shadow sm:p-6 rounded-lg border">
          <article aria-labelledby="question-title-81614">
            <div>
              <div class="flex space-x-3">
                <div class="flex-shrink-0">
                  <img
                    class="h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                    alt=""
                  />
                </div>
                <div class="min-w-0 flex-1">
                  <p class="text-sm font-medium text-gray-900">
                    <a href="#" class="hover:underline">Dries Vincent</a>
                  </p>
                  <p class="text-sm text-gray-500">
                    <a href="#" class="hover:underline"
                      >{{data.attributes.updatedAt}}</a
                    >
                  </p>
                </div>
              </div>
              <h2
                id="question-title-81614"
                class="mt-4 text-base font-medium text-gray-900"
              >
                {{data.attributes.title}}
              </h2>
            </div>
            <div class="mt-2 text-sm text-gray-700 space-y-4">
              <p>{{data.attributes.abstract}}</p>
            </div>
            <div class="mt-6 flex justify-end">
              <div class="flex text-sm">
                <span class="inline-flex items-center text-sm">
                  <button
                    class="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      class="h-5 w-5"
                      x-description="Heroicon name: solid/share"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
                      ></path>
                    </svg>
                    <span class="font-medium text-gray-900">Share</span>
                  </button>
                </span>
              </div>
            </div>
          </article>
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "DashboardUser",
  data: function () {
    return {
      // isOpen: false,
      user: null,
      show: false,
      data:[]
    };
  },


  computed: {
    ...mapGetters({
      authUser: 'login/authUser',
      authStatus: 'login/authStatus',
      getTotalStudies: 'users/getTotalUserStudies',
      getTotalProposals: 'proposals/getTotalUserProposals',
      getStudiesByUser: 'users/getStudiesByUser',
      getProposalsByUser: 'proposals/getProposalsByUser',
      getUser: 'users/getCurrentUser',
      getUserOpd: 'users/getUserOpd',
    }),

    latestData() {
      return this.getStudiesByUser.concat(this.getProposalsByUser)
    }
  },

  methods: {
    ...mapActions({
      fetchStudies: 'users/fetchStudiesByUser',
      fetchProposals: 'proposals/fetchProposalsByUser',
      fetchCurrent: 'users/fetchCurrentUser',
      fetchUserOpd: 'users/fetchUserOpd'
    })
  },

  created(){
    this.fetchStudies({
      pageNumber: 1,
      pageSize: 2
    });
    this.fetchProposals({
      pageNumber: 1,
      pageSize: 2
    });
    this.fetchCurrent();
    this.fetchUserOpd();
  },

};
</script>
