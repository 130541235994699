<template>
  <div>
    <NavbarUser></NavbarUser>
    <main class="min-h-screen mx-auto">
      <router-view :key="$route.path"></router-view>
    </main>
    <div class="bottom-0 w-full">
      <default-footer></default-footer>
    </div>
  </div>
</template>

<script>
import NavbarUser from "@/components/NavbarUser.vue"
import DefaultFooter from "@/components/DefaultFooter.vue"

export default {
  name: "LayoutDefault",
  components: {
    NavbarUser, 
    DefaultFooter
  }
}
</script>