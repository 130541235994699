<template>
  <div class="max-w-5xl mx-auto flex flex-col min-w-0 flex-1 overflow-hidden mt-8">
    <div class="pb-5 border-b border-gray-200">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Profil Saya
      </h3>
    </div>

    <main class="relative z-0 overflow-y-auto focus:outline-none xl:order-last mt-8">
      <!-- Profile header -->
      <div>
        <div class="w-full justify-center items-center overflow-hidden md:max-w-sm rounded-lg mx-auto">
          <div class="relative shadow mx-auto h-24 w-24 border-white rounded-full overflow-hidden border-4">
            <img class="object-cover w-full h-full" :src="'/api/' + getUser.attributes.imageProfile.url" @error="$event.target.src = require(`@/assets/avatar-placeholder.png`)">
          </div>
          <div class="mt-4">
            <h1 class="text-xl font-bold text-center font-semibold">
              {{getUser.attributes.name}} 
            </h1>
          </div>
        </div>
      </div>

      <div class="m-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div>
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Informasi Pribadi
            </h3>
            <!-- <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Personal details and application.
            </p> -->
          </div>
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                NIP
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{getUser.attributes.nip}}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                OPD
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{getUserOpd.attributes.name}}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Alamat Email
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{getUser.attributes.email}}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="m-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div>
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Informasi Keamanan
            </h3>
            <!-- <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Personal details and application.
            </p> -->
          </div>
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Password
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                ⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Terakhir login
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{getUser.attributes.lastActive}}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Status
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{getUser.attributes.isSuspended}}
              </dd>
            </div>
          </dl>
        </div>
      </div>

        <!-- Team member list -->
        <!-- <div class="mt-8 px-4 pb-12 sm:px-6 lg:px-8">
          <h2 class="text-sm font-medium text-gray-500">Team members</h2>
          <div class="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            
            <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixqx=zEeVAn6MTu&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="">
              </div>
              <div class="flex-1 min-w-0">
                <a href="#" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <p class="text-sm font-medium text-gray-900">
                    Leslie Alexander
                  </p>
                  <p class="text-sm text-gray-500 truncate">
                    Co-Founder / CEO
                  </p>
                </a>
              </div>
            </div>
            
            <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&amp;ixqx=zEeVAn6MTu&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="">
              </div>
              <div class="flex-1 min-w-0">
                <a href="#" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <p class="text-sm font-medium text-gray-900">
                    Michael Foster
                  </p>
                  <p class="text-sm text-gray-500 truncate">
                    Co-Founder / CTO
                  </p>
                </a>
              </div>
            </div>
            
            <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&amp;ixqx=zEeVAn6MTu&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="">
              </div>
              <div class="flex-1 min-w-0">
                <a href="#" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <p class="text-sm font-medium text-gray-900">
                    Dries Vincent
                  </p>
                  <p class="text-sm text-gray-500 truncate">
                    Manager, Business Relations
                  </p>
                </a>
              </div>
            </div>
            
            <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&amp;ixqx=zEeVAn6MTu&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="">
              </div>
              <div class="flex-1 min-w-0">
                <a href="#" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <p class="text-sm font-medium text-gray-900">
                    Lindsay Walton
                  </p>
                  <p class="text-sm text-gray-500 truncate">
                    Front-end Developer
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div> -->
    </main>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data: function () {
    return {
      
    };
  },
  computed: {
    ...mapGetters({
      getUser: 'users/getCurrentUser',
      getUserOpd: 'users/getUserOpd'      
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrent: 'users/fetchCurrentUser',
      fetchUserOpd: 'users/fetchUserOpd',
    }),
  },
  created(){
    this.fetchCurrent();
    this.fetchUserOpd();
  }
};
</script>
