<template>
  <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
    <div class="my-10 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200">
        <div v-if="isCLoading" class="w-1/3 container mx-auto px-6 flex flex-col justify-between items-center relative">
          <Loading></Loading>  
        </div>
        <div class="bg-white shadow sm:rounded-lg" v-else>
          <div class="px-4 py-5 sm:px-6">
            <h2
              id="applicant-information-title"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              {{getDetail.data.data.attributes.title}}
            </h2>
            <div class="flex justify-end gap-2" v-if="isLoggedIn">
                <router-link
                  v-bind:to="'/user/editkelitbangan/'+getDetail.data.data.id"
                  class="inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  
                  Edit
                </router-link>
                <router-link
                  v-bind:to="'/user/editkelitbangan/'+getDetail.data.data.id"
                  class="inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Hapus
                </router-link>    
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3" >
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Tema
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getThemesID.attributes.name}}
                </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">Penyusun</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.team}}
                </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">Kontak</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.contact}}
                </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Bidang
                </dt>
                <dd class="mt-1 text-sm text-gray-900"> {{getSectorID.attributes.name}}  </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Dibuat tanggal
                </dt>
                <dd class="mt-1 text-sm text-gray-900"> {{getDetail.data.data.attributes.createdAt}}  </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Permasalahan</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.problem}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Latar Belakang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.background}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Metode</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.method}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Hasil</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.result}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Status</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.data.attributes.status}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                <dd class="mt-1 text-sm text-gray-900" v-for="dataFile in getDetail.included" :key="dataFile.id">
                  <ul class="border border-gray-200 rounded-md divide-y divide-gray-200 mt-2" v-if="dataFile.type == 'filestudies'">
                    <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div class="w-0 flex-1 flex items-center">
                        <svg
                          class="flex-shrink-0 h-5 w-5 text-gray-400"
                          x-description="Heroicon name: solid/paper-clip"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="ml-2 flex-1 w-0 truncate">
                          {{dataFile.attributes.name}}
                        </span>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <a
                          :href="`/` + `${dataFile.attributes.url}`"
                          target="_blank" 
                          class="font-medium text-blue-600 hover:text-blue-500"
                        >
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Loading from '@/components/LoadingSVG.vue'

export default {
  name: 'DetailPenelitian',
  components: {
    Loading
  },

  data() {
    return {
      studies_id: this.$route.params.studies_id,
    }
  },

  computed: {
    getLoginStatus() {
      return this.isLoggedIn;
    },

    ...mapGetters({
      getDetail: 'studies/getDetailStudy',
      getThemesID: 'studies/getThemesID',
      getSectorID: 'studies/getSectorID',
      isLoggedIn: 'login/isLoggedIn'
    }),
    ...mapState({
      isCLoading: state => state.studies.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      StudyDetails: 'studies/StudyDetails'
    }),
    detailStudies(){
      return this.studies= this.getDetail
    }
  },
  created (){
    // this.StudyDetails(this.studies_id);
    this.StudyDetails(this.studies_id);    
  }
}
</script>