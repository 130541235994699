<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
      <transition
        enter-active-class="transition ease-linear duration-300"
        enter-class="opacity-0 scale-75"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition ease-in-out duration-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-75"
      >
        <router-view :key="$route.fullpath" />
      </transition>
    </component>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      layout: "div",
    };
  },
};
</script>