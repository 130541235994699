<template>
  <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
    <div class="my-10 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200">
        <div v-if="isCLoading" class="w-1/3 container mx-auto px-6 flex flex-col justify-between items-center relative">
          <Loading></Loading>  
        </div>
        <div class="bg-white shadow sm:rounded-lg" v-else>
          <!-- <h1>{{getDetail.data.attributes.title}}</h1> -->
          <div class="px-4 py-5 sm:px-6">
            <h2
              id="applicant-information-title"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              {{getDetail.data.attributes.title}}
            </h2>
              <div class="flex justify-end gap-2" v-if="isLoggedIn">
                  <router-link
                    v-bind:to="'/user/editusulan/' + getDetail.data.id"
                    class="inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  >
                  
                    Edit
                  </router-link>
                  <router-link
                    v-bind:to="'/user/editusulan/' + getDetail.data.id"
                    class="inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Hapus
                  </router-link>    
              </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3" >
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Tema
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  Peta Budaya: Gallery, Museum dan Desa Wisata Budaya RIK 2
                  <!-- {{getThemesID.attributes.name}} -->
                </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">Penyusun</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.team}}
                </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">Kontak</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.contact}}
                </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Bidang
                </dt>
                <dd class="mt-1 text-sm text-gray-900"> Sosial Budaya  </dd>
              </div>

              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Dibuat tanggal
                </dt>
                <dd class="mt-1 text-sm text-gray-900"> {{getDetail.data.attributes.createdAt}}  </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Permasalahan</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.problem}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Latar Belakang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.background}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Metode</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.method}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Hasil</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.result}}
                </dd>
              </div>

              <div class="sm:col-span-3">
                <dt class="text-sm font-medium text-gray-500">Status</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{getDetail.data.attributes.status}}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Loading from '@/components/LoadingSVG.vue'

export default {
  name: 'DetailUsulan',
  components: {
    Loading
  },

  data() {
    return {
      proposal_id: this.$route.params.proposal_id,
    }
  },

  computed: {
    ...mapGetters({
      getDetail: 'proposals/getDetailProposal',
      isLoggedIn: 'login/isLoggedIn'
      // getThemesID: 'proposals/getThemesID',
      // getSectorID: 'proposals/getSectorID',
    }),
    ...mapState({
      isCLoading: state => state.proposals.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      ProposalDetails: 'proposals/ProposalDetails'
    }),
    detailProposal(){
      return this.proposal= this.getDetail
    }
  },
  created (){
    // this.ProposalDetails(this.studies_id);
    this.ProposalDetails(this.proposal_id);    
  }
}
</script>