<template>
<div class="flex-1 overflow-x-hidden overflow-y-auto mx-auto w-full">
  <div class="container mx-auto px-4 pt-6 pb-4 w-full">
    <!-- <breadcrumb /> -->
    <Breadcrumbs class="flex bg-white rounded-md"/>
  </div>
  <router-view />
</div>
</template>
<script>
export default {
  name: "LayoutOPD",

  methods: {
    selected(crumb) {
      console.log(crumb);
    },
  },
}
</script>
<style>
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  list-style: none;  
  margin-top: 0;
  margin-bottom: 16px;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 6px;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block; 
  color: #2c3e50;
  content: '/';
}
.breadcrumb-item a {
  font-size: 14px;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}
.breadcrumb-item span {
  color: #0471d8;
}
</style>