<template>
  <div>
    <navbar></navbar>
    <main class="min-h-screen mx-auto">
      <slot/>
    </main>
    <div class="bottom-0 w-full">
      <default-footer></default-footer>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import DefaultFooter from "@/components/DefaultFooter.vue"

export default {
  name: "LayoutDefault",
  components: {
    Navbar, 
    DefaultFooter
  }
}
</script>