import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  permissions: []
};

const getters = {
  getPermissions: (state) => {
    return state.permissions
  },

  getTotalPermissions: (state) => {
    return state.permissions.length
  }
};

const mutations = {
  REQUEST_PERMISSIONS(state) {
    state.isLoading = true
  },

  SET_PERMISSIONS(state, response) {
    state.permissions = response.data
  }
};

const actions = {
  async fetchAllPermissions({commit}) {
    commit('REQUEST_PERMISSIONS')

    try {
      let response = await ApiService.get('/api/v1/permissions')
      console.log("PERMISSIONS: ", response)
      commit('SET_PERMISSIONS', response)
    } catch (e) {
      console.log('FetchAllPermissions : ' + e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}


