import ApiService from "@/services/api.service";

const state = {
	isLoading: false,
  details: {
    data:{}
  },
  submit: {},
  file: {},
	proposals: {
		meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {}
	},
	proposal: {
		meta: {
			page: {}
		},
		data: {}
	},
	pagination: {},
	proposalSearchResult: {
		data: {},
		meta: {}
	},
	proposalsByUser: {
		data: {},
		meta: {
			page: {
			}
		}
	},
  proposal_id:null
};

const getters = {
  getLoading: (state) => {
    return state.isLoading
  },
	getPagination(state){
		return state.pagination;
	},

	getProposals: (state) => {
		return state.proposals
	},

	getProposal: (state) => {
		// console.log("PROPOSAL: "+JSON.stringify(state.proposals))
		return state.proposal.data
	},
	
	getMetaProposals: (state) => {
		return state.proposal.meta
	},

	getTotalProposal: (state) => {
		return state.proposal
	},

	getProposalSearchResult: (state) => {
		return state.proposalSearchResult.data
	},

	getPageCountSearchResult:(state) => {
		return state.proposalSearchResult.meta.lastPage
	},

	getMetaSearchResult:(state) => {
		return state.proposalSearchResult.meta
	},

	getProposalsByUser: (state) => {
		return state.proposalsByUser.data
	},

	getPageCountUserProposals: (state) => {
		return state.proposalsByUser.meta.lastPage
	},

	getTotalUserProposals: (state) => {
		return state.proposalsByUser.meta.total
	},

	getSubmitData: (state) => {
		// return console.log("DETAILS SUBMIT: "+JSON.stringify(state.submit))
		return state.submit
	},

	getDetailProposal: (state) => {
		// return console.log("DETAILS PROPOSALS: "+JSON.stringify(state.details))
		return state.details.data
  },

  getThemesID: (state) => {
    let theme_id = state.details.included.filter((include) => {
      if(include.type=='themes'){
        return include.id
      }
    })
    // return console.log('THEME: '+state)
    return theme_id[0]
  },

  getSectorID: (state) => {
    let sector_id = state.details.included.filter((include) => {
      if(include.type=='sectors'){
        return include.id
      }
    })
    // return console.log('Sectors: '+JSON.stringify(sector_id))
    return sector_id[0]
  }
};

const mutations = {
	REQUEST_PROPOSAL(state,status) {
		state.isLoading = status
	},

	SET_PROPOSALS(state, response) {
		state.proposals = response
	},

	SET_PROPOSAL(state, response) {
		state.proposal = response
	},

	SET_PAGINATION(state, response){
		state.pagination = response;
	},

	SET_PROPOSALS_SEARCH_RESULT(state, response){
		state.proposalSearchResult.data = response.data;
		state.proposalSearchResult.meta = response.meta.page;
	},

	SET_USER_PROPOSALS(state, response){
		state.proposalsByUser.data = response.data
		state.proposalsByUser.meta = response.meta.page
	},

	SET_FORM_SUBMIT(state, response){
		state.submit = response
	},

	SET_UPLOAD_FILE(state, response){
		state.file = response
	},

	SET_PROPOSAL_ID(state, response){
		state.proposal_id = response
	},

	SET_PROPOSAL_DETAILS(state, response){
		state.details = response
	},
};

const actions = {
	async fetchAllProposals({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_PROPOSAL', true)
		if(sortType=='desc'){
      sortName = '-' + sortName
    }
    try {
      let response = await ApiService.get('/api/v1/proposals?include=user,theme,opd,sector&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' +  sortName)
      commit('SET_PROPOSALS', response.data);
    } catch (e) {
      console.log('FetchAllProposals : ' + e)
    }
    commit('REQUEST_PROPOSAL', false)
  },
  async recentProposals({commit}, {pageNumber, pageSize}) {
		commit('REQUEST_PROPOSAL', true)

		try {
			let response = await ApiService.get('/api/v1/proposals?page[number]=' + pageNumber + '&page[size]='+ pageSize + '&sort=-updatedAt')
			commit('SET_PROPOSAL', response.data);
		} catch (e) {
			console.log('FetchAllProposal : ' + e)
		}
		commit('REQUEST_PROPOSAL', false)
	},

	async fetchProposalsByUser({commit}, {pageNumber, pageSize}) {
		let user = JSON.parse(localStorage.getItem('me'));
		commit('REQUEST_PROPOSAL', true)

		try {
			let response = await ApiService.get('/api/v1/users/' + user.uuid + '/proposals?page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=-updatedAt')
			commit('SET_USER_PROPOSALS', response.data)
		} catch (e) {
			console.log('Fetch Proposals by User : ' + e)
		}
		commit('REQUEST_PROPOSAL', false)
	},

	async searchProposalsByResource({commit}, {resource_name, resource_id}) {    
    commit('REQUEST_PROPOSALS', true)

    try {
      let response = await ApiService.get('/api/v1/'+ resource_name + '/' + resource_id + '/proposals')
      let seacrhResultProposals = response.data.data;
      commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
    } catch (e) {
      console.log('Search Proposals by ' + resource_name + ' error : ' + e )
    }
		commit('REQUEST_PROPOSAL', false)
  },

	async searchProposalsByAttribute({commit}, {attribute_name, keyword}) {    
    commit('REQUEST_PROPOSALS', true)

		if(attribute_name!=='status' && attribute_name!=='all') {
			try {
				let response = await ApiService.get('/api/v1/proposals?filter[search-'+ attribute_name + ']=' + keyword)
				let seacrhResultProposals = response.data.data;
				commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
			} catch (e) {
				console.log('Search Proposals by ' + attribute_name + ' error : ' + e )
			}
		} else if (attribute_name==='all') {
			try {
				let response = await ApiService.get('/api/v1/proposals?filter[search-all-columns]=' + keyword)
				let seacrhResultProposals = response.data.data;
				commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
			} catch (e) {
				console.log('Search Proposals by ' + attribute_name + ' error : ' + e )
			}
		} else {
			try {
				let response = await ApiService.get('/api/v1/proposals?filter[status]=' + keyword)
				let seacrhResultProposals = response.data.data;
				commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
			} catch (e) {
				console.log('Search Proposals by ' + attribute_name + ' error : ' + e )
			}
		}
		commit('REQUEST_PROPOSAL', false)
  },

  async searchAllProposals({commit}, keyword){
		commit('REQUEST_PROPOSAL', true)
		try {
			let response = await ApiService.get('/api/v1/proposals?filter[search-all-columns]=' + keyword)
			let seacrhResultProposals = response.data.data;
			// console.log('HASIL SEARCH: '+JSON.stringify(seacrhResultProposals))
			commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
		} catch (e) {
			console.log('Search Proposals by all columns error : ' + e )
		}
		commit('REQUEST_PROPOSAL', false)
  },

	async filterProposalByRIK({commit}, {rik_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_PROPOSAL', true)
		if(rik_id == '') {
			try {
				let response = await ApiService.get('/api/v1/proposals?filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=-createdAt')
				let seacrhResultProposals = response.data;
				// console.log('HASIL FILTER BY RIK: '+JSON.stringify(seacrhResultProposals))
				commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
			} catch (e) {
				console.log('Filtered Proposal By RIK ERROR : ' + e )
			}
		} else {
			try {
				let response = await ApiService.get('/api/v1/riks/'+ rik_id +'/proposals?filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
				let seacrhResultProposals = response.data;
				// console.log('HASIL FILTER BY RIK: '+JSON.stringify(seacrhResultProposals))
				commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
			} catch (e) {
				console.log('Filtered Proposal By RIK ERROR : ' + e )
			}
		}
		commit('REQUEST_PROPOSAL', false)
  },
	
	async filterProposalBySector({commit}, {rik_id, sector_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_PROPOSAL', true)
		try {
			let response = await ApiService.get('/api/v1/riks/' + rik_id + '/proposals?filter[sector_id]=' + sector_id + '&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
			let seacrhResultProposals = response.data;
			// console.log('HASIL FILTER BY SECTOR: '+JSON.stringify(seacrhResultProposals))
			commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
		} catch (e) {
			console.log('Filtered Proposal By SECTOR ERROR : ' + e )
		}
		commit('REQUEST_PROPOSAL', false)
  },

	async filterProposalByOpd({commit}, {rik_id, sector_id, opd_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_PROPOSAL', true)
		try {
			let response = await ApiService.get('/api/v1/riks/'+ rik_id +'/proposals?filter[sector_id]=' + sector_id + '&filter[opd_id]=' + opd_id + '&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
			let seacrhResultProposals = response.data;
			// console.log('HASIL FILTER BY OPD: '+JSON.stringify(seacrhResultProposals))
			commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
		} catch (e) {
			console.log('Filtered Proposal By OPD ERROR : ' + e )
		}
		commit('REQUEST_PROPOSAL', false)
  },

	async filterProposalByThemes({commit}, {rik_id, sector_id, opd_id, theme_id, attribute_name, keyword, pageNumber, pageSize}){
		commit('REQUEST_PROPOSAL', true)
		try {
			let response = await ApiService.get('/api/v1/riks/'+ rik_id +'/proposals?filter[sector_id]=' + sector_id + '&filter[opd_id]=' + opd_id + '&filter[theme_id]=' + theme_id + '&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize)
			let seacrhResultProposals = response.data;
			// console.log('HASIL FILTER BY THEME: '+JSON.stringify(seacrhResultProposals))
			commit('SET_PROPOSALS_SEARCH_RESULT', seacrhResultProposals)
		} catch (e) {
			console.log('Filtered Proposal By THEME ERROR : ' + e )
		}
		commit('REQUEST_PROPOSAL', false)
  },

  async submitForm({commit, state}, {formdata, config}){
    commit('REQUEST_PROPOSAL', true)
    console.log("dari module studies state " + state.isLoading)
    try{
      let response = await ApiService.post('/api/v1/proposals', formdata, config)
      // console.log(formdata)
      let submitData = response.data.data;
      let ProposalID = response.data.data.id;
      // console.log("Submit Form tulis " +state.isLoading)
      // console.log("Success create kegiatan" + JSON.stringify(submitData));
      commit('SET_FORM_SUBMIT', submitData)
      commit('SET_PROPOSAL_ID', ProposalID)
    } catch(e){
      console.log("Module Submit Form Eror nulis")
      // console.log("button clicked but still got error: " + e);
    }
    commit('REQUEST_PROPOSAL', false)
  },

	async updateProposal({ commit }, { id, data }) {
		commit('REQUEST_PROPOSAL', true)
		let token = localStorage.getItem("token")

		const config = {
			'Accept': 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
			'Authorization': `Bearer ${token}`,
		};
		try {
			let response = await ApiService.patch(`/api/v1/proposals/${id}`, data, config)
			// console.log(formdata)
			let submitData = response.data.data;
			let ProposalID = response.data.data.id;
			// console.log("Submit Form tulis " +state.isLoading)
			// console.log("Success create kegiatan" + JSON.stringify(submitData));
			commit('SET_FORM_SUBMIT', submitData)
			commit('SET_PROPOSAL_ID', ProposalID)
		} catch (e) {
			console.log("Module Submit Form Eror nulis")
			// console.log("button clicked but still got error: " + e);
		}
		commit('REQUEST_PROPOSAL', false)
	},

  async uploadFilesProposal({commit}, {formdata, config}){
    commit('REQUEST_PROPOSAL', true)
    try {
      let response = await ApiService.post('/api/v1/fileproposals', formdata, config)
      let uploaded = response.data.data
      commit('SET_UPLOAD_FILE', response.data);
      console.log('UPLOAD SUCCESS')
      console.log('FILE TERUPLOAD: '+JSON.stringify(uploaded))
      commit('REQUEST_PROPOSAL')
    } catch (e) {
    console.log('FetchAllStudies : ' + e)
    }
    commit('REQUEST_PROPOSAL', false)
  }, 

  async ProposalDetails({commit}, proposal_id) {
    commit('REQUEST_PROPOSAL', true)
    console.log("state.isLoading")
    try {
      let response = await ApiService.get('/api/v1/proposals/'+ proposal_id + '?include=opd,sector,theme')
      let sector_id = ""
      sector_id = response.data.included.filter((include) => {
        if(include.type=='sectors'){
          return include.id
        }
      }).map(function(data) {
        return data.id
      })
      console.log("sector id from propsals details: "+sector_id)
      commit('SET_PROPOSAL_DETAILS', response)
		return response;
    //   console.log("ISI PROPOSALS DETAIL"+JSON.stringify(response))
    } catch (e) {
      console.log('Fetch Study error : ' + e)
    } finally {
		commit('REQUEST_PROPOSAL', false)
	}
  },
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}