<template>
  <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
    <form class="my-10 space-y-8 divide-y divide-gray-200" >
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Tambah Usulan
          </h3>
          <p class="mt-1 text-sm text-gray-500">Menambah Usulan</p>
        </div>

        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4 mt-4">
            <label for="opd" class="block text-sm font-medium text-gray-700">
              RIK
            </label>
            <div class="mt-1">
              <select
                v-model="form.rik"
                @change="changeRIK"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" selected disabled data-default>Pilih RIK</option>
                <option
                  v-for="rik in getRiks"
                  :value="rik.id"
                  :key="rik.id"
                >
                  {{ rik.attributes.endYear }}
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="judul" class="block text-sm font-medium text-gray-700">
              Bidang
            </label>
            <div class="mt-1">
              <select
                id="bidang"
                v-model="form.bidang"
                @change="changeSector"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" selected disabled data-default>Pilih Bidang</option>
                <option
                  v-for="bidang in getSectorName"
                  :value="bidang.id"
                  :key="bidang.id"
                >
                  {{ bidang.attributes.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="tema" class="block text-sm font-medium text-gray-700">
              Tema
            </label>
            <div class="mt-1">
                <multiselect ref="multiselectTheme" v-model="form.tema"  placeholder="Pilih Tema" label="name" track-by="id" :options="getThemesBySector?.map(it => ({ id: it.id, name: it.attributes.name }))" :multiple="false" :taggable="false"></multiselect>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="judul" class="block text-sm font-medium text-gray-700">
              Judul Usulan
            </label>
            <div class="mt-1">
              <input
                id="judulusulan"
                v-model="form.judulusulan"
                type="text"
                required
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="penyusun" class="block text-sm font-medium text-gray-700">
              Penyusun
            </label>
            <div class="mt-1">
              <input
                id="penyusun"
                v-model="form.penyusun"
                type="text"
                value="Biro Pemerintahan"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="contact" class="block text-sm font-medium text-gray-700">
              Kontak
            </label>
            <div class="mt-1">
              <input
                id="contact"
                v-model="form.contact"
                type="text"
                value="Biro Pemerintahan"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label
              for="permasalahan"
              class="block text-sm font-medium text-gray-700"
            >
              Permasalahan pokok yang dibahas dalam usulan
            </label>
            <div class="mt-1">
              <textarea
                id="permasalahan"
                v-model="form.permasalahan"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="latarbelakang"
              class="block text-sm font-medium text-gray-700"
            >
              Alasan atau latar belakang dilakukannya usulan
            </label>
            <div class="mt-1">
              <textarea
                id="latarbelakang"
                v-model="form.latarbelakang"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-6">
            <label for="metode" class="block text-sm font-medium text-gray-700">
              Bagaimana usulan dilakukan dan metode yang digunakan dalam
              usulan
            </label>
            <div class="mt-1">
              <textarea
                id="metode"
                v-model="form.metode"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="prospek"
              class="block text-sm font-medium text-gray-700"
            >
              Pernyataan singkat tentang kegiatan yang telah dilakukan (hasil)
              serta prospeknya
            </label>
            <div class="mt-1">
              <textarea
                id="prospek"
                v-model="form.prospek"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p class="mt-2 text-xs italic text-gray-500">
              kurang lebih 5 kalimat
            </p>
          </div>

          <div class="sm:col-span-4">
            <label
              for="katakunci"
              class="block text-sm font-medium text-gray-700"
            >
              Kata kunci
            </label>
            <div class="mt-1">
              <input
                id="katakunci"
                v-model="form.keywords"
                type="text"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <p class="mt-2 text-xs italic text-gray-500">3 sampai 5 kata</p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="cover_photo"
              class="block text-sm font-medium text-gray-700"
            >
              Unggah Documents
            </label>
            <div
              class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
            >
              <div class="space-y-1 text-center">
                <div class="text-sm text-gray-600">
                  <label
                    for="file-upload"
                    class="hover:bg-indigo-50 p-2 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Unggah</span>
                    <input
                      id="file-upload"
                      type="file"
                      @change="handleFileUpload"
                      class="sr-only"
                    />
                  </label>
                </div>
                <p class="mt-2 text-xs text-gray-500">{{uploadedfiles}}</p>
                <p class="mt-2 text-xs text-gray-500">Format yang didukung PDF</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <template v-if="!isEdit">
          <button
            type="button"
            @click="submit('draft')"
            class="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Simpan sebagai Draft
          </button>
          <button
            type="button"
            @click="submit('submitted')"
            class="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Simpan
          </button>
          </template>
          <template v-else>
            <button
              type="button"
              @click="edit()"
              class="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Simpan
            </button>
          </template>
          <router-link to="/user/dashboard">
            <button
              type="button"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import axios from "axios";
import Multiselect from 'vue-multiselect';
import ApiService from "@/services/api.service";

export default {
  name: "TambahUsulan",
  components: {
    Multiselect
  },
  data() {
    return {
      isEdit: false,
      form: {
        rik: "",
        bidang: "",
        tema: "",
        judulusulan: "",
        opdpengampu: "",
        contact: "",
        penyusun: "",
        permasalahan: "",
        latarbelakang: "",
        metode: "",
        prospek: "",
        tag: "",
        keywords: "",
      },
      searchTheme: "",
      uploadedfiles: null
    };
  },

  computed:{
    ...mapGetters({
      getSectorName: 'opds/getSector',
      getUser: 'opds/getUser',
      getName: 'opds/getName',
      getOpd: 'opds/getOpd',
      getRiks: 'riks/getRiks',
      getThemesBySector: 'themes/getThemesBySector',
      getSubmitData: 'proposals/getSubmitData',
      getCLoading: 'proposals/getLoading'
    }),

    ...mapState({
      isCLoading: state => state.proposals.isLoading,
      proposal_id: state => state.proposals.proposal_id
    })
    
  },

  methods: {
    ...mapActions({
      fetchAllSectors: 'sectors/fetchAllSectors',
      fetchAllRiks: 'riks/fetchAllRiks',
      fetchSectorsByOpd: 'opds/fetchSectorsByOpd',
      fetchThemesWithoutSector: 'themes/fetchThemesWithoutSector',
      submitForm: 'proposals/submitForm',
      editForm: 'proposals/updateProposal',
      uploadFilesProposal: 'proposals/uploadFilesProposal',
      ProposalDetails: 'proposals/ProposalDetails',
      // fetchAllOpdId: 'opds/fetchOpdByID'
    }),
    handleFileUpload() {
      this.uploadedfiles = event.target.files[0];
    },

    async edit() {
      let token = localStorage.getItem("token");
      let user = JSON.parse(localStorage.getItem('me')).uuid
      let opd = JSON.parse(localStorage.getItem('me')).opd_id

      const config = {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
      };

      await this.editForm({id: this.$route.params.id, data: {
          "data": {
            "type": "proposals",
            id: this.$route.params.id,
            "attributes": {
              "title": this.form.judulusulan,
              "team": this.form.penyusun,
              "contact": this.form.contact,
              "problem": this.form.permasalahan,
              "background": this.form.latarbelakang,
              "method": this.form.metode,
              "result": this.form.prospek,
              "keyword": this.form.keywords,
            },
            "relationships": {
              "user": {
                "data": {
                  "type": "users",
                  "id": user
                }

              },
              "theme": {
                "data": {
                  "type": "themes",
                  "id": this.form.tema?.id
                }

              },
              "opd": {
                "data": {
                  "type": "opds",
                  "id": opd
                }

              },
              "sector": {
                "data": {
                  "type": "sectors",
                  "id": this.form.bidang
                }
              },
            }
          }
        } }, config)
        this.$router.push('/user/dashboard')
    },

    async submit(status){
      let token = localStorage.getItem("token");
      let user = JSON.parse(localStorage.getItem('me')).uuid
      let opd = JSON.parse(localStorage.getItem('me')).opd_id

      const config = {        
        'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
      };

      const raw = JSON.stringify({
        "data": {
          "type": "proposals",
          "attributes": {
            "title": this.form.judulusulan,
            "team": this.form.penyusun,
            "contact": this.form.contact,
            "problem": this.form.permasalahan,
            "background": this.form.latarbelakang,
            "method": this.form.metode,
            "result": this.form.prospek,
            "keyword": this.form.keywords,
            "status": status
          },
          "relationships": {
            "user": {
              "data": {
                "type": "users",
                "id": user
              }
                
            },
            "theme": {
              "data": {
                "type": "themes",
                "id": this.form.tema?.id
              }
                
            },
            "opd": {
              "data": {
                "type": "opds",
                "id": opd
              }
                
            },
            "sector": {
              "data": {
                "type": "sectors",
                "id": this.form.bidang
              }
            },
        }
        }
      });

      await this.submitForm({
        formdata: raw,
        config: config
      })

      if(this.uploadedfiles == null){
        console.log("tanpa file")
        console.log("PROPOSAL ID"+JSON.stringify(this.getSubmitData.id))
        this.$router.push('/user/detailusulan/'+this.getSubmitData.id)
      }
      else {
        console.log("dengan file")
        this.uploadfiles(this.getSubmitData.id)
        this.$router.push('/user/detailusulan/'+this.getSubmitData.id)
      }

    },

    uploadfiles(proposal_id){
      console.log("CLICKED UPLOAD BUTTON")

      let token = localStorage.getItem("token");
      var data = new FormData();
      data.append('file', this.uploadedfiles);
      data.append('proposal_id', proposal_id);

      const config = {        
        'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      };

      this.uploadFilesProposal({
        formdata: data,
        config: config})
    },
    
    changeRIK(event) {
      const rik_id = event.target.value;
      this.fetchSectorsByOpd(rik_id);
    },
    changeSector() {
      // const sector_id = event.target.value;
      this.fetchThemesWithoutSector();
    },

    setProposal(id) {
      this.ProposalDetails(id).then(response => {
        console.log({response})
        this.form.abstract = response.data.data.attributes.abstract;
        this.form.latarbelakang = response.data.data.attributes.background;
        this.form.contact = response.data.data.attributes.contact;
        this.form.metode = response.data.data.attributes.method ;
        this.form.permasalahan = response.data.data.attributes.problem;
        this.form.prospek = response.data.data.attributes.result;
        this.form.penyusun = response.data.data.attributes.team;
        this.form.judulusulan = response.data.data.attributes.title;
        this.form.keywords = response.data.data.attributes.keyword;
        const themes = response.data.included.find(it => it.id == response.data.data.relationships.theme.data.id)
        this.form.tema = {
          id: themes.id,
          name: themes.attributes.name
        }
        const sectors = response.data.included.find(it => it.id == response.data.data.relationships.sector.data.id)
        this.form.tema = {
          id: themes.id,
          name: themes.attributes.name
        }
        this.form.bidang = sectors.id;
        ApiService.get('/api/v1/sectors/' + sectors.id + '/rik').then(response => {
          this.form.rik = response.data.data.id
          this.fetchSectorsByOpd(response.data.data.id);
        })

        
      })
    }
  },

  mounted() { 
    const self = this;
    this.$refs.multiselectTheme.$refs.search.oninput = function (event) {
      self.fetchThemesWithoutSector(event.target.value);
    }
    if(this.$route.params.id) {
      this.setProposal(this.$route.params.id)
    }
    // this.ProposalDetails(this.proposal_id);

  },

  created() {
    this.isEdit = !!this.$route.params.id;
    this.fetchAllSectors();
    this.fetchAllRiks();
    // this.fetchSectorsByOpd(thi)
    this.$store.dispatch('opds/fetchOpdByID', JSON.parse(localStorage.getItem('me')).opd_id);
  },
};
</script>