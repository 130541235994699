import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  error: {
    errorMessage: '',
    errorCode: 0
  },
  user: {},
  users: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {},
    included: {}
  },
  usrstudies: {
    data: {},
    meta: {
      page: {

      }
    }
  },
  current: {
    id: '',
    attributes: {
      email: '',
      imageProfile: {},
      name: '',
      nip: ''
    }
  },
  user_id: {},
  opd: {
    attributes:{
      name: ''
    }
  }
};

const getters = {
  getUsers: (state) => {
    // return console.log("USER: "+JSON.stringify(state.users))
    return state.users
  },

  getTotalUsers: (state) => {
    return state.users.length
  },

  getStudiesByUser: (state) => {
    return state.usrstudies.data
  },

  getPageCountUserStudies: (state)=> {
    return state.usrstudies.meta.lastPage
  },
  
  getTotalUserStudies: (state) => {
    return state.usrstudies.meta.total // From meta.count .. don't counting from length
  },

  getCurrentUser: (state) => {
    // return console.log("CURRENT: "+JSON.stringify(state.current))
    return state.current
  },

  getUserOpd: (state) => {
    return state.opd
  },

  getError: (state) => {
    return state.error
  }
};

const mutations = {
  REQUEST_USERS(state, status) {
    state.isLoading = status
  },

  SET_USERS(state, response) {
    state.users = response
  },

  SET_USER(state, response) {
    state.user = response
  },

  SET_USER_STUDIES(state,response){
    state.usrstudies.data = response.data
    state.usrstudies.meta = response.meta.page
  },

  SET_CURRENT_USER(state,response){
    state.current = response.data
  },

  SET_USER_OPD(state,response){
    state.opd = response.data
  },

  SET_ERROR(state, {errorMessage, errorCode}){
    state.error.errorMessage = errorMessage
    state.error.errorCode = errorCode
  }
};

const actions = {
  async createUser({commit}, {data, config}){
    commit('REQUEST_USERS', true)
    try {
      let response = await ApiService.post('/api/v1/users', data, config)
      commit('SET_USER', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_USERS', false)
  },

  async updateUser({commit}, {data, config}){
    commit('REQUEST_USERS', true)
    const form = JSON.parse(data)
    try {
      let response = await ApiService.patch('/api/v1/users/' + form.data.id, data, config)
      commit('SET_USER', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_USERS', false)
  },

  async fetchAllUsers({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_USERS', true)
    if(sortType=='desc'){
      sortName = '-' + sortName
    }
    try {
      let response = await ApiService.get('/api/v1/users?include=opd,roles&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
      commit('SET_USERS', response.data)
      commit('REQUEST_USERS', false)
    } catch (e) {
      commit('REQUEST_USERS', false)
      console.log('FetchAllUsers : ' + e)
    }
  },
  
  async fetchStudiesByUser({commit}, {pageNumber, pageSize}) {
    let user = JSON.parse(localStorage.getItem('me'));
    commit('REQUEST_USERS', true)
    
    try {
      let response = await ApiService.get('/api/v1/users/'+ user.uuid +'/studies?page[number]=' + pageNumber + '&page[size]='+ pageSize + '&sort=-updatedAt')
      commit('SET_USER_STUDIES', response.data)
      commit('REQUEST_USERS', false)
    } catch (e) {
      commit('REQUEST_USERS', false)
      console.log('fetchStudiesByUser : ' + e)
    }
  },
  
  async fetchCurrentUser({commit}) {
    let user = JSON.parse(localStorage.getItem('me'));
    commit('REQUEST_USERS', true)
    
    try {
      let response = await ApiService.get('/api/v1/users/'+ user.uuid + '?include=opd,roles')
      commit('SET_CURRENT_USER', response.data)
      commit('REQUEST_USERS', false)
      return response;
    } catch (e) {
      console.log('fetchCurrentUser : ' + e)
      commit('REQUEST_USERS', false)
    }
  },
  
  async fetchUserOpd({commit}) {
    let opd_id = JSON.parse(localStorage.getItem('me')).opd_id;
    commit('REQUEST_USERS', true)
    
    try {
      let response = await ApiService.get('/api/v1/opds/'+ opd_id)
      commit('SET_USER_OPD', response.data)
      commit('REQUEST_USERS', false)
    } catch (e) {
      commit('REQUEST_USERS', false)
      console.log('fetchUserOpd : ' + e)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
