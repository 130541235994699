import Vue from "vue";
import Vuex from "vuex";

import permissions from "@/store/modules/permissions.module";
import opds from "@/store/modules/opds.module";
import riks from "@/store/modules/riks.module";
import proposals from "@/store/modules/proposals.module";
import roles from "@/store/modules/roles.module";
import sectors from "@/store/modules/sectors.module";
import studies from "@/store/modules/studies.module";
import tags from "@/store/modules/tags.module";
import themes from "@/store/modules/themes.module";
import users from "@/store/modules/users.module";
import login from "@/store/modules/login.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    permissions,
    proposals,
    roles,
    sectors,
    studies,
    tags,
    themes,
    users,
    opds,
    riks
  },
});
