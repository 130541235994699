export default function shortString(dataString){
	let data = String(dataString)
	let maxLength = 250

	let trimmedString = data.substr(0, maxLength);

	//re-trim if we are in the middle of a word
	trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))

	if(data.length > trimmedString.length){
		return trimmedString + "..."
	} else {
		return trimmedString
	}

}