<template>
  <div class="bg-white dark:bg-gray-800 relative items-center">
    <!-- HEADER -->
    <div class="max-h-screen container mx-auto px-6 flex flex-col justify-between items-center relative pt-8">
      <div class="flex items-center justify-center h-screen">
        <div class="h-2/4">
          <h1 class="font-bold w-full uppercase text-center text-4xl sm:text-5xl dark:text-white text-gray-800">
            Sistem Informasi Hasil Kelitbangan
          </h1>
          <h2 class="font-light max-w-2xl mx-auto w-full text-xl dark:text-white text-gray-500 text-center py-8">
            Kumpulan hasil kelitbangan dan kajian dari semua OPD
          </h2>
          
          <div class="relative shadow flex">
            <input
              class="w-full rounded-lg p-4"
              type="text"
              placeholder="Cari Kelitbangan..."
              v-model="keyword" @keyup.enter="submitSearch()"
            />
            <div class="absolute inset-y-0 right-12 flex items-center">
              <label for="currency" class="sr-only">Filter</label>
              <select id="currency" name="currency" v-model="pencarian" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                <option value="hasilkelitbangan">Hasil Kelitbangan</option>
                <option value="usulan">Usulan</option>
              </select>
            </div>
            <router-link :to="'/' + this.pencarian + '/'+this.keyword"
              class="bg-gray-100 hover:bg-gray-300 w-auto flex justify-end items-center text-blue-500 px-4 py-2 hover:text-blue-400"
            >
            <!-- <div> -->
              <svg
                class="text-gray-600 h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
                style="enable-background: new 0 0 56.966 56.966"
                xml:space="preserve"
                width="512px"
                height="512px"
              >
                <path
                  d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                />
              </svg>
            <!-- </div> -->
            </router-link>
          </div>

          <div>
            <dl class="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2">
              <router-link to="/hasilkelitbangan">
              <div class="relative bg-white py-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt class="flex content-center">
                  <div class="bg-blue-500 rounded-md p-3"> 
                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                  <div class="flex-row ml-4">
                    <p class="text-sm font-medium text-gray-500 truncate">Total Hasil Kelitbangan</p>
                    <p class="text-2xl font-semibold text-gray-900">
                      {{totalStudies.page.total}}
                    </p>
                  </div>
                </dt>
              </div>
              </router-link>

              <router-link to="/usulan">
                <div class="relative bg-white py-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                  <dt class="flex content-center">
                    <div class="bg-indigo-500 rounded-md p-3">                    
                      <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </div>
                    <div class="flex-row ml-4">
                      <p class="text-sm font-medium text-gray-500 truncate">Total Hasil Usulan</p>
                      <p class="text-2xl font-semibold text-gray-900">
                        {{totalProposals.page.total}}
                      </p>
                    </div>
                  </dt>
                </div>
              </router-link>

            </dl>
          </div>
        </div>
      </div>
      
      <div class="bottom-16 inset-x-0 rounded-lg z-100 w-full">
        <img class="w-full" src="@/assets/city_2.svg" />
      </div>
    </div> 

    <!-- SECTION 1 -->
    <div class="w-full">
      <div class="bg-blue-200">
        <div class="max-w-7xl mx-auto px-4 py-6 md:p-6 lg:p-8">
          <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div class="p-2 md:p-4">
              <div
                class="w-full rounded-lg overflow-hidden shadow-lg hover:bg-white transition duration-500 bg-white"
              >
                <div class="space-y-10">
                  <div class="px-6 py-4 mt-4">
                    <div class="space-y-2">
                      <div class="text-center">
                        <img
                          class="mx-auto flex items-center justify-center w-20 h-20 mb-4"
                          src="@/assets/penelitian.svg"
                        />
                        <h1 class="text-gray-700 font-bold text-2xl ">Hasil Kelitbangan</h1>
                        <p class="text-medium">Menampilkan Hasil Kelitbangan terbaru</p>
                      </div>
                      <div class="flow-root mt-6">
                        <ul class="divide-y divide-gray-200" v-for="studies in getStudies.data" :value="studies.id" :key="studies.id">
                          <li class="py-2 max-h-40 overflow-ellipsis overflow-hidden">
                            <div class="relative focus-within:ring-2 focus-within:ring-indigo-500">
                              <h3 class="text-medium font-bold text-gray-800">
                                <router-link
                                  :to="'/detailkelitbangan/'+studies.id"
                                  class="hover:underline focus:outline-none"
                                >                                  
                                  <span
                                    class="absolute inset-0"
                                    aria-hidden="true"
                                  ></span>
                                  {{studies.attributes.title}}
                                </router-link>
                              </h3>
                              <h3 class="text-sm font-semibold text-gray-800">
                                {{studies.attributes.team}}
                              </h3>
                              <p
                                class="mt-1 text-sm text-gray-600 line-clamp-2 overflow-ellipsis overflow-hidden text-justify"
                              >
                                {{studies.attributes.abstract | truncate(200)}}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="mt-6">
                        <router-link to="/hasilkelitbangan" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                          Lihat Semua
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2 sm:p-4">
              <div
                class="w-full rounded-lg overflow-hidden shadow-lg hover:bg-white transition duration-500 bg-white"
              >
                <div class="space-y-10">
                  <div class="px-6 py-4 mt-4">
                    <div class="space-y-2">
                      <div class="text-center">
                        <img
                          class="mx-auto flex items-center justify-center w-20 h-20 mb-4"
                          src="@/assets/penelitian.svg"
                        />
                        <h1 class="text-gray-700 font-bold text-2xl ">Usulan</h1>
                        <p class="text-medium">Menampilkan Usulan terbaru</p>
                      </div>

                      <div class="flow-root mt-6">
                        <ul class="divide-y divide-gray-200" v-for="data in getProposal" :value="data.id" :key="data.id">
                          <li class="py-2 max-h-40 overflow-ellipsis overflow-hidden">
                            <div
                              class="relative focus-within:ring-2 focus-within:ring-indigo-500"
                            >
                              <h3 class="text-medium font-bold text-gray-800">
                                <router-link :to="'/detailusulan/'+data.id" class="hover:underline focus:outline-none">                                  
                                  <span
                                    class="absolute inset-0"
                                    aria-hidden="true"
                                  ></span>
                                  {{data.attributes.title}}
                                </router-link>
                              </h3>
                              <h3 class="text-sm font-semibold text-gray-800">
                                {{data.attributes.team}}
                              </h3>
                              <p
                                class="mt-1 text-sm text-gray-600 line-clamp-2 overflow-ellipsis overflow-hidden text-justify"
                              >
                                {{data.attributes.abstract | truncate(200)}}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="mt-6">
                        <router-link to="/usulan" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                          Lihat Semua
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Home",
  
  data() {
    return {
      attribute_name: "all",
      keyword: "",
      pencarian: "hasilkelitbangan"
    }
  },

  computed: {
    ...mapGetters({
      totalStudies: 'studies/getMetaDetails',
      totalProposals: 'proposals/getMetaProposals',
      getStudies: 'studies/getDetailStudy',
      getProposal: 'proposals/getProposal'
    }),

  },
  methods: {
    ...mapActions({
      recentProposals: 'proposals/recentProposals',
      recentStudies: 'studies/recentStudies'
    }),

    submitSearch() {
      // console.log("Enter trigger")
      // let keywords = this.keyword
      this.$router.push({name: 'HasilKelitbangan', params: { keywords: this.keyword }})
      // this.$router.push({path: '/hasilkelitbangan', params: { keywords: this.keyword }})
    },

    clicked(){
      
    }
  },
  created(){
    this.recentStudies({
      pageNumber: 1,
      pageSize: 3
    });    
    this.recentProposals({
      pageNumber: 1,
      pageSize: 3
    });    
  },

  // filters: {
  //   truncate: function (value, length) {
  //     if(value.length < length) {
  //       return value;
  //     }

  //     length = length - 3;
  //     return value.substring(0, length) + '...';
  //   }
  // }
};
</script>