import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  tags: []
};

const getters = {
  getTags: (state) => {
    return state.tags
  },

  getTotalTags: (state) => {
    return state.tags.length
  }
};

const mutations = {
  REQUEST_TAGS(state) {
    state.isLoading = true
  },

  SET_TAGS(state, response) {
    state.tags = response.data
  }
};

const actions = {
  async fetchAllTags({commit}) {
    commit('REQUEST_TAGS')

    try {
      let response = await ApiService.get('/tags')
      commit('SET_TAGS', response)
    } catch (e) {
      console.log('FetchAllTags : ' + e)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}