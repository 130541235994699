<template>    
	<div :class="sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'" class="fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform bg-blue-700 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0">
    <div class="flex items-center justify-center mt-8">
      <div class="flex items-center">
        <span class="text-white text-2xl mx-2 font-semibold">ROOT</span>
      </div>
    </div>

    <nav class="mt-10">
      <router-link
        to="/admin/dashboard"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
        </svg>
        <span class="mx-3">Dashboard</span>
      </router-link>

      <router-link
        to="/admin/rik"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
        <span class="mx-3">RIK</span>
      </router-link>

      <router-link
        to="/admin/bidang"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
        </svg>
        <span class="mx-3">Bidang</span>
      </router-link>

      <router-link
        to="/admin/tema"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
        </svg>
        <span class="mx-3">Tema</span>
      </router-link>
      
      <router-link
        to="/admin/opd"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
        <span class="mx-3">OPD</span>
      </router-link>

      <router-link
        to="/admin/hasil-kelitbangan"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span class="mx-3">Hasil Kelitbangan</span>
      </router-link>

      <router-link
        to="/admin/usulan"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span class="mx-3">Usulan</span>
      </router-link>

      <router-link
        to="/admin/user"
        active-class="bg-white text-blue-700 border-r-8 border-blue-400"
        class="flex items-center my-2 mx-4 p-4 text-white hover:bg-white hover:text-blue-800 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
        <span class="mx-3">User</span>
      </router-link>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'DefaultSidebar',
  data: function() {
    return {
      // isOpen: false,
      sidebarOpen: false,
    };
  },
  created() {

  },
  methods: {}
};
</script>