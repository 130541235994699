<template>
<main>
  <div class="container mx-auto px-4 py-6 w-full">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <form v-on:submit.prevent="createData">
        <!--body-->
        <div class="relative p-6 flex-auto">
          <div class="mb-4">
            <label for="name" class="block text-sm text-gray-600 dark:text-gray-400">Nama OPD</label>
            <input v-model="dataOpd.name" type="text" name="name" id="name" placeholder="Nama" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
          </div>
          <div class="mb-4">
            <label for="description" class="block text-sm text-gray-600 dark:text-gray-400">Deskripsi</label>
            <textarea v-model="dataOpd.description" rows="5" name="description" id="description" placeholder="Deskripsi" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" required></textarea>
          </div>
          <div class="mb-4">
            <label for="sector" class="block text-sm text-gray-600 dark:text-gray-400">Bidang</label>
						<multiselect v-model="dataOpd.sectors.selectedSectors" placeholder="Search or add a sector" label="name" track-by="id" :options="getSector" :multiple="true" :taggable="false"></multiselect>
          </div>
          <div class="mb-4">
            <label class="block text-sm text-gray-600 dark:text-gray-400">Tema</label>
            <multiselect v-model="dataOpd.themes.selectedThemes" placeholder="Search or add a theme" label="name" track-by="id" :options="getThemes" :multiple="true" :taggable="false"></multiselect>
          </div>
        </div>
        
        <!--footer-->
        <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
          <router-link 
						to="/admin/opd"
            class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2">
            Batal
          </router-link>
          <button 
            type="submit"
            class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg">
            Simpan Data
          </button>
        </div>
      </form>
    </div>
  </div>
</main>
</template>
<script>
import { mapGetters, mapActions} from 'vuex';
import Multiselect from 'vue-multiselect';
export default {
  name: "TambahOPD",
  components: { Multiselect },
  data() {
		return {
			is_edit: false,
			dataOpd: {
				id: '',
				name: '',
				description: '',
				sectors: {
					selectedSectors: [],
					optionSectors: []
				},
				themes: {
					selectedThemes: [],
					optionThemes: []
				}
			}
		}
	},

  computed: {
    ...mapGetters({
			getOpds: 'opds/getPaginateOpds',
			getOpd: 'opds/getOpd',
			getAllSectors: 'sectors/getSectors',
			getThemesBySector: 'themes/getThemesBySector',
			getAllThemes: 'themes/getThemes',
			getErrorOpd: 'opds/getError'
		}),
		
		getSector(){
			const sectors = this.getAllSectors.data.map(item => {
				const data = {
					id: item.id,
					name: item.attributes.name
				}
				return data
			})
			return sectors
		},

    getThemes(){
			const themes = this.getAllThemes.data.map(item => {
				const data = {
					id: item.id,
					name: item.attributes.name
				}
				return data
			})
			return themes
		}
  },

  methods: {
  ...mapActions({
			fetchAllOpds: 'opds/fetchPaginateOpds',
			fetchOpdById: 'opds/fetchOpdByID',
			fetchAllSectors: 'sectors/fetchAllSectors',
			fetchThemesBySector: 'themes/fetchThemes',
			fetchAllThemes: 'themes/fetchAllThemes',
			createOpd: 'opds/createOpd',
			updateOpd: 'opds/updateOpd'
		}),

    createData() {
			let token = localStorage.getItem("token")
			
			const config = {
				'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
			};

			const raw = {
				data: {
					type: "opds",
					attributes: {
						name: this.dataOpd.name,
						description: this.dataOpd.description,
					},
					relationships: {
						sectors: {
							data: this.dataOpd.sectors.selectedSectors.map(sector => {
								return {
									type: "sectors",
									id: sector.id
								}
							})
						},
						themes: {
							data: this.dataOpd.themes.selectedThemes.map(theme => {
								return {
									type: "themes",
									id: theme.id
								}
							})
						}
					}
				}
			}
			if(this.is_edit){
				raw.data.id = this.dataOpd.id
				this.updateOpd({
					data: raw,
					config: config
				}).then(() => {
					if(this.getErrorOpd.errorCode == 0){
						this.openCreateData = !this.openCreateData
						this.$router.push('/admin/opd')
					}
				})
			} else {
				this.createOpd({
					data: raw,
					config: config
				}).then(() => {
					if(this.getErrorOpd.errorCode == 0){
						this.openCreateData = !this.openCreateData
						this.$router.push('/admin/opd')
					}
				})
			}
		},
    resetData(){
			this.dataOpd = {
				id: '',
				name: '',
				description: '',
				sectors: {
					selectedSectors: [],
					optionSectors: []
				},
				themes: {
					selectedThemes: [],
					optionThemes: []
				}
			}
		},
		getRelationships(resource, id){
			let data = resource.included.filter(function (el) {
				return el.id === id
			});
			return data[0]
		},
  },

  created() {
		this.fetchAllSectors();
		this.fetchAllThemes();
		if(this.$route.params.id){
			this.fetchOpdById({
				id: this.$route.params.id
			}).then((response) => {
				this.is_edit = true
				const opd = response.data.data
				this.dataOpd.id = opd.id
				this.dataOpd.name = opd.attributes.name
				this.dataOpd.description = opd.attributes.description
				this.dataOpd.sectors.selectedSectors = opd.relationships['sectors'].data.map((sector) => {
					return {
						id: sector.id,
						name: this.getRelationships(response.data, sector.id).attributes.name
					}
				})
				this.dataOpd.themes.selectedThemes = opd.relationships['themes'].data.map((theme) => {
					return {
						id: theme.id,
						name: this.getRelationships(response.data, theme.id).attributes.name
					}
				})
			})
		}
	},

}
</script>