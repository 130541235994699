import ApiService from "@/services/api.service";


const state = {
  isLoading: false,
  error: {
    errorMessage: '',
    errorCode: 0
  },
  sectors: {
    data: []
  },
  sector: {},
  sectors_rik: [],
  opd_id: [],
  paginateSectors: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {},
    included: {}
  }
};

const getters = {
  getSectors: (state) => {
    // return console.log("SECTOR: "+JSON.stringify(state.sectors))
    return state.sectors
  },

  getSector: (state) => {
    return state.sector
  },

  getPaginateSectors: (state) => {
    return state.paginateSectors
  },

  getTotalSectors: (state) => {
    return state.sectors.length
  },

  getOpd: (state) => {
    return state.opd
  },

  getSectorByRIK: (state) => {
    // return console.log('HASIL SECTOR BY RIK: '+JSON.stringify(state.sectors_rik))
    return state.sectors_rik
  },
  getOpdsBySector: (state) => {
    return state.opd_id
  },
  getError: (state) => {
    return state.error
  }
};

const mutations = {
  REQUEST_SECTORS(state, status) {
    state.isLoading = status
  },

  SET_SECTORS(state, response) {
    state.sectors = response
  },
  
  SET_SECTOR(state, sector) {
    state.sector = sector
  },

  SET_PAGINATE_SECTORS(state, response) {
    state.paginateSectors = response
  },
  
  SET_OPD_BY_SECTOR(state, response){
    state.opd_id = response
  },
  SET_SECTOR_BY_RIKS(state, response){
    state.sectors_rik = response
  },
  CLEAR_SECTOR_BY_RIK(state) {
    state.sectors_rik = []
  },

  CLEAR_OPD_BY_SECTOR(state) {
    state.opd_id = []
  },

  SET_ERROR(state, {errorMessage, errorCode}){
    state.error.errorMessage = errorMessage
    state.error.errorCode = errorCode
  }
};

const actions = {
  async createSector({commit}, {data, config}){
    commit('REQUEST_SECTORS', true)
    try {
      let response = await ApiService.post('/api/v1/sectors', data, config)
      commit('SET_SECTOR', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_SECTORS', false)
  },

  async updateSector({commit}, {data, config}){
    commit('REQUEST_SECTORS', true)
    const form = JSON.parse(data)
    try {
      let response = await ApiService.patch('/api/v1/sectors/' + form.data.id, data, config)
      commit('SET_SECTOR', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_SECTORS', false)
  },

  async fetchAllSectors({commit}) {
    commit('REQUEST_SECTORS', true)

    try {
      let response = await ApiService.get('/api/v1/sectors')
      let total = response.data.meta.page.total
      let totalResponse = await ApiService.get('/api/v1/sectors?page[number]=1&page[size]=' + total )
      commit('SET_SECTORS', totalResponse.data)
    } catch (e) {
      console.log('FetchAllSectors : ' + e)
    }
    commit('REQUEST_SECTORS', false)
  },

  async fetchPaginateSectors({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_SECTORS', true)
    if(sortType=='desc'){
      sortName = '-' + sortName
    }
    try {
      let response = await ApiService.get('/api/v1/sectors?include=rik&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
      commit('SET_PAGINATE_SECTORS', response.data)
    } catch (e) {
      console.log('FetchAllSectors : ' + e)
    }
    commit('REQUEST_SECTORS', false)
  },

  async fetchSectorsByRiks({commit}, rik_id) {    
    commit('REQUEST_SECTORS', true)

    try {
      let response = await ApiService.get('/api/v1/sectors?filter[rik-id]='+ rik_id)
      let sectors_rik = response.data.data;
      // console.log('HASIL SECTORS FILTER BY RIK: '+JSON.stringify(sectors_rik))
      commit('SET_SECTOR_BY_RIKS', sectors_rik)
      commit('REQUEST_SECTORS', false)
    } catch (e) {
      console.log('Fetch Sector ID by RIK ID got error: ' + e)
    }
    commit('REQUEST_SECTORS', false)
  },

  async fetchOpdsBySector({commit}, sector_id) {    
    commit('REQUEST_SECTORS', true)

    try {
      let response = await ApiService.get('/api/v1/sectors/'+ sector_id + '/opds')
      let opd_id = response.data.data;
      commit('SET_OPD_BY_SECTOR', opd_id)
    } catch (e) {
      console.log('Fetch OPD by Sector ID got error: ' + e)
    }
    commit('REQUEST_SECTORS', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
