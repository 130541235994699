<template>
  <div>
    <nav class="bg-blue-50 md:bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0 font-bold text-2xl">SIHK</div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline">
                <router-link
                  to="/"
                  class="px-3 py-2 rounded-md text-sm font-medium text-blue-600 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >Beranda</router-link>
                <router-link
                  to="/hasilkelitbangan"
                  class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-blue-600 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >Hasil Kelitbangan</router-link>
                <router-link
                  to="/usulan"
                  class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-blue-600 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >Usulan</router-link>
              </div>
            </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
              <!-- Log In Button -->
              <div class="ml-3 relative absolute z-10"  v-if="isLoggedIn">
                <div class="lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                  <!-- Profile dropdown -->
                  <div class="flex-shrink-0 relative ml-4">
                    <div>
                      <button @click="show = !show" type="button" class="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu" aria-expanded="false" aria-haspopup="true">
                        <span class="sr-only">Open user menu</span>
                        <img class="h-8 w-8 rounded-full" :src="getUser.attributes.imageProfile" @error="$event.target.src = require(`@/assets/avatar-placeholder.png`)" alt="Image Profile">
                      </button>
                    </div>
                    <transition name="fade">
                      <div v-if="show" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <router-link
                          to="/"
                          class="block sm:hidden py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                        >Beranda</router-link>
                        <router-link
                          to="/hasilkelitbangan"
                          class="block sm:hidden py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                        >Hasil Kelitbangan</router-link>
                        <router-link
                          to="/usulan"
                          class="block sm:hidden py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                        >Usulan</router-link>
                        <router-link to="/user/dashboard" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Dashboard</router-link>
                        <a @click="logout" class="cursor-pointer block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Keluar</a>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>

              <div v-else class="ml-3 relative">
                <router-link
                  to="/login"
                  class="hidden sm:block bg-blue-600 px-3 py-1 text-blue-200 text-sm rounded-md focus:outline-none focus:shadow-solid focus:text-white focus:bg-blue-800 hover:text-white hover:bg-blue-700"
                  id="log-in"
                  aria-label="Login button"
                  aria-haspopup="true"
                >Log In</router-link>

                <div class="-mr-2 flex md:hidden">
                  <!-- Mobile menu button -->
                  <button
                    @click="isMenuOpen = !isMenuOpen"
                    class="inline-flex items-center justify-center p-2 rounded-md text-blue-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 focus:text-white"
                  >
                    <!-- Menu open: "hidden", Menu closed: "block" -->
                    <svg class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                  <!-- Close Modal Menu Outer Window --> 
                  <button v-if="isMenuOpen" @click="isMenuOpen= false" tabindex="-1" class="fixed inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
                </div>

                <!-- mobile version -->
                <transition name="fade">
                  <div class="absolute z-10 w-full bg-blue-50" v-if="isMenuOpen">
                    <div class="px-2 pt-2 pb-3 sm:px-3">
                      <router-link
                        to="/"
                        class="block px-3 py-2 rounded-md text-base font-medium text-white bg-blue-900 focus:outline-none focus:text-white focus:bg-blue-700"
                      >Beranda</router-link>
                      <router-link
                        to="/hasilkelitbangan"
                        class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-blue-500 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                      >Hasil Kelitbangan</router-link>
                      <router-link
                        to="/usulan"
                        class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-blue-500 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                      >Usulan</router-link>
                    </div>
                    <div class="pt-4 pb-3 border-t border-blue-700">
                      <div class="flex items-center px-5">
                        <div class="flex-shrink-0">
                          <router-link
                            to="/login"
                            class="bg-blue-600 px-3 py-1 text-blue-200 text-sm rounded-md focus:outline-none focus:shadow-solid focus:text-white focus:bg-blue-800 hover:text-white hover:bg-blue-700"
                            id="log-in"
                            aria-label="Login button"
                            aria-haspopup="true"
                          >Log In</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
        </div>
      </div>      
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'NavbarDefault',
  data: function() {
    return {
      // isOpen: false,
      isMenuOpen: false,
      show: false,
      showProfile: false
    };
  },
  created() {
    this.fetchCurrent()
  },
  computed:{
    ...mapGetters({
      getUser: 'users/getCurrentUser',
      isLoggedIn: 'login/isLoggedIn'    
    }),

    getLoginStatus() {
      return this.isLoggedIn;
    },
    // isLoggedIn : function(){
    //   return this.$store.getters.isAuthenticated
    // } 
  },

  methods: {
    async logout (){
      // await this.$store.dispatch('LogOut')
      localStorage.removeItem('token')
      this.$router.push('/login')
    },

    ...mapActions({
      fetchCurrent: 'users/fetchCurrentUser',    
    }),
  },
  
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>