<template>	
	<main class="flex-1 overflow-x-hidden overflow-y-auto">
		<div class="container mx-auto px-4 pt-6 pb-4 w-full">
			<Breadcrumbs class="flex bg-white rounded-md mt-4"/>
		</div>
		<div class="container mx-auto px-6 pb-8">
			<div class="md:flex md:items-center md:justify-between">
				<div class="flex-1 min-w-0">
					<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
						Data User
					</h2>
				</div>
				<div class="mt-4 flex md:mt-0 md:ml-4">
					<div class="mr-2">
						<form @submit.prevent="searchData">
							<div class="relative mx-4 lg:mx-0">
								<span class="absolute inset-y-0 left-0 pl-3 flex items-center">
									<svg class="h-5 w-5 text-gray-500" viewBox="0 0 24 24" fill="none">
										<path
												d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
												stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
										</path>
									</svg>
								</span>
								<input
									class="w-32 sm:w-64 rounded-lg pl-10 pr-4"
									type="text"
									placeholder="Cari User..."
									v-model="search.keyword"
								/>
							</div>
						</form>
					</div>

					<button 
						type="button"
						class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg"
						@click="toggleCreateData()">
						Tambah Data
					</button>
				</div>
			</div>
			
			<div class="flex flex-col mt-4">				
				<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div
						class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table class="min-w-full">
							<thead>
								<tr>
									<th
										class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-1/12">
										No</th>
									<th
										@click="sortData('name')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12">
										Nama</th>
									<th
										@click="sortData('email')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12">
										Email</th>
									<th
										@click="sortData('nip')"
										class="px-6 py-3 cursor-pointer border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12">
										NIP</th>
									<th
										class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12">
										OPD</th>
									<th
										class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-2/12">
										Role</th>
									<th
										class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-1/12">
										Aksi</th>
								</tr>
							</thead>

							<tbody class="bg-white">
								<tr v-for="(user, index) in getUsers.data" :value="user.id" :key="user.id">
									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{(getUsers.meta.page.currentPage * getUsers.meta.page.perPage) - getUsers.meta.page.perPage  + index + 1}}
										</div>
									</td>
									
									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{user.attributes.name}}
										</div>
									</td>

									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{user.attributes.email}}
										</div>
									</td>

									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{user.attributes.nip}}
										</div>
									</td>
									
									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{getIncluded(getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type)
												.attributes.name}}
										</div>
									</td>

									<td class="px-6 py-4 border-b border-gray-200">
										<div class="text-sm leading-5 text-gray-900">
											{{ (user.relationships.roles.data[0] !== undefined) ? getIncluded(getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).attributes.name : "-" }}

										</div>
									</td>

									<td
										class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium">
										<div class="flex items-center">
											<button @click="detailData(user)" class="p-3 text-indigo-600 hover:text-indigo-900">
												<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M9.99996 5.99998C9.99996 7.10455 9.10453 7.99998 7.99996 7.99998C6.89539 7.99998 5.99996 7.10455 5.99996 5.99998C5.99996 4.89541 6.89539 3.99998 7.99996 3.99998C9.10453 3.99998 9.99996 4.89541 9.99996 5.99998Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M1.63879 5.99996C2.48831 3.29523 5.01517 1.33331 8.00025 1.33331C10.9854 1.33331 13.5122 3.29526 14.3617 6C13.5122 8.70473 10.9854 10.6666 8.00027 10.6666C5.01516 10.6666 2.4883 8.70471 1.63879 5.99996Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</button>
											<button @click="editData(user)" class="p-3 text-indigo-600 hover:text-indigo-900">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.3333 9.77335V13.3334C13.3333 13.687 13.1928 14.0261 12.9427 14.2762C12.6927 14.5262 12.3535 14.6667 11.9999 14.6667H2.66659C2.31296 14.6667 1.97382 14.5262 1.72378 14.2762C1.47373 14.0261 1.33325 13.687 1.33325 13.3334V4.00002C1.33325 3.6464 1.47373 3.30726 1.72378 3.05721C1.97382 2.80716 2.31296 2.66669 2.66659 2.66669H6.22659" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M11.9999 1.33331L14.6666 3.99998L7.99992 10.6666H5.33325V7.99998L11.9999 1.33331Z" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</button>
											<button @click="deleteData(user)" class="p-3 text-indigo-600 hover:text-indigo-900">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M2 4H3.33333H14" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M5.33325 3.99998V2.66665C5.33325 2.31302 5.47373 1.97389 5.72378 1.72384C5.97383 1.47379 6.31296 1.33331 6.66659 1.33331H9.33325C9.68687 1.33331 10.026 1.47379 10.2761 1.72384C10.5261 1.97389 10.6666 2.31302 10.6666 2.66665V3.99998M12.6666 3.99998V13.3333C12.6666 13.6869 12.5261 14.0261 12.2761 14.2761C12.026 14.5262 11.6869 14.6666 11.3333 14.6666H4.66659C4.31296 14.6666 3.97382 14.5262 3.72378 14.2761C3.47373 14.0261 3.33325 13.6869 3.33325 13.3333V3.99998H12.6666Z" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M6.66675 7.33331V11.3333" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M9.33325 7.33331V11.3333" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</button>
										</div>
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
					<Paginate 
						:page-count="getUsers.meta.page.lastPage"
						:page-range="2"
						:click-handler="paginateUsers"
						:prev-text="'Prev'"
						:prevClass="'-mt-px w-0 flex-1 flex'"
						:prev-link-class="'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
						:next-text="'Next'"
						:nextClass="'-mt-px w-0 flex-1 flex justify-end'"
						:next-link-class="'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
						:page-class="'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
						:activeClass="'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
						:container-class="'border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'"
						class="py-4">
					</Paginate>
				</div>
			</div>

			<div v-if="openCreateData" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex bg-gray-400 bg-opacity-50">
				<div class="relative w-2/3 my-6 mx-auto max-w-xl">
					<!--content-->
					<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<!--header-->
					<form v-on:submit.prevent="createData">
						<div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
							<h3 class="text-xl font-semibold">
								Tambah User
							</h3>
							<button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="createData()">
								<span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
									×
								</span>
							</button>
						</div>
						<div v-if="getErrorUser.errorMessage!=''" class="mt-4 mx-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
							<span class="block sm:inline">{{getErrorUser.errorMessage}}</span>
							<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
								<svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
							</span>
						</div>
						<div v-if="!isPassword" class="mt-4 mx-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
							<span class="block sm:inline">Password and password confirmation are different</span>
							<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
								<svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
							</span>
						</div>
						
						<!--body-->
						<div class="relative p-6 flex-auto">
							<div class="mb-4">
								<label for="name" class="block text-sm text-gray-600 dark:text-gray-400">Nama</label>
								<input v-model="dataUser.name" type="text" name="name" id="name" placeholder="Nama" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
							</div>
							<div class="mb-4">
								<label for="email" class="block text-sm text-gray-600 dark:text-gray-400">Email</label>
								<input v-model="dataUser.email" type="email" name="email" id="email" placeholder="Email" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
							</div>
							<div class="mb-4">
								<label for="nip" class="block text-sm text-gray-600 dark:text-gray-400">NIP</label>
								<input v-model="dataUser.nip" type="text" name="nip" id="nip" placeholder="NIP" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
							</div>
							<div class="grid grid-cols-2 gap-4">
								<div class="mb-4">
									<label for="password" class="block text-sm text-gray-600 dark:text-gray-400">Password</label>
									<input v-model="dataUser.password" type="password" name="password" id="password" placeholder="Password" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
								</div>
								<div class="mb-4">
									<label for="password2" class="block text-sm text-gray-600 dark:text-gray-400">Konfirmasi Password</label>
									<input v-model="dataUser.password2" type="password" name="password2" id="password2" placeholder="Konfirmasi Password" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
								</div>
							</div>
							<div class="mb-4">
								<label for="opd" class="block text-sm text-gray-600 dark:text-gray-400">OPD</label>
								<select v-model="dataUser.opd.id" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" required>
									<option value="" selected disabled>Pilih OPD</option>
									<option
										v-for="opd in getOpds.data"
										:value="opd.id"
										:key="opd.id"
									>
										{{ opd.attributes.name }}
									</option>
								</select>
							</div>
							<div class="mb-4">
								<label for="role" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Role</label>
								<div class="grid grid-cols-4 gap-2">
									<div v-for="role in getRoles" :value="role.id" :key="role.id">
										<input type="radio" v-model="dataUser.role.id" required :id="role.id" name="role" :value="role.id" class="text-sm text-gray-600 dark:text-gray-400 capitalize"> {{role.attributes.name}}
									</div>
								</div>
							</div>
						</div>
						<!--footer-->
						<div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
							<button 
								type="button"
								class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2"
								@click="toggleCreateData()">
								Batal
							</button>
							<button 
								type="submit"
								class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg">
								Simpan Data
							</button>
						</div>
					</form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="openEditData" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex bg-gray-400 bg-opacity-50">
			<div class="relative w-2/3 my-6 mx-auto max-w-xl">
				<!--content-->
				<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
					<!--header-->
				<form v-on:submit.prevent="updateData">
					<div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
						<h3 class="text-xl font-semibold">
							Edit User
						</h3>
						<button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="createData()">
							<span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
								×
							</span>
						</button>
					</div>
					<div v-if="getErrorUser.errorMessage!=''" class="mt-4 mx-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
						<span class="block sm:inline">{{getErrorUser.errorMessage}}</span>
						<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
							<svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
						</span>
					</div>
					
					<!--body-->
					<div class="relative p-6 flex-auto">
						<div class="mb-4">
							<label for="name" class="block text-sm text-gray-600 dark:text-gray-400">Nama</label>
							<input v-model="dataUser.name" type="text" name="name" id="name" placeholder="Nama" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
						</div>
						<div class="mb-4">
							<label for="email" class="block text-sm text-gray-600 dark:text-gray-400">Email</label>
							<input v-model="dataUser.email" type="email" name="email" id="email" placeholder="Email" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
						</div>
						<div class="mb-4">
							<label for="nip" class="block text-sm text-gray-600 dark:text-gray-400">NIP</label>
							<input v-model="dataUser.nip" type="text" name="nip" id="nip" placeholder="NIP" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
						</div>
						<div class="mb-4">
							<label for="opd" class="block text-sm text-gray-600 dark:text-gray-400">OPD</label>
							<select v-model="dataUser.opd.id" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" required>
								<option value="" selected disabled>Pilih OPD</option>
								<option
									v-for="opd in getOpds.data"
									:value="opd.id"
									:key="opd.id"
								>
									{{ opd.attributes.name }}
								</option>
							</select>
						</div>
						<div class="mb-4">
							<label for="role" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Role</label>
							<div class="grid grid-cols-4 gap-2">
								<div v-for="role in getRoles" :value="role.id" :key="role.id">
									<input type="radio" v-model="dataUser.role.id" required :id="role.id" name="role" :value="role.id" class="text-sm text-gray-600 dark:text-gray-400 capitalize"> {{role.attributes.name}}
								</div>
							</div>
						</div>
					</div>
					<!--footer-->
					<div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
						<button 
							type="button"
							class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md hover:bg-gray-50 mr-2 self-start"
							@click="toggleChangePassword()">
							Ubah Password
						</button>
						<button 
							type="button"
							class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2"
							@click="toggleEditData()">
							Batal
						</button>
						<button 
							type="submit"
							class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg">
							Perbaharui Data
						</button>
					</div>
				</form>
				</div>
			</div>
		</div>

			<div v-if="openChangePassword" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex bg-gray-400 bg-opacity-50">
				<div class="relative w-2/3 my-6 mx-auto max-w-xl">
					<!--content-->
					<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<!--header-->
					<form v-on:submit.prevent="changePassword">
						<div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
							<h3 class="text-xl font-semibold">
								Ubah Password
							</h3>
							<button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="createData()">
								<span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
									×
								</span>
							</button>
						</div>
						<div v-if="getErrorUser.errorMessage!=''" class="mt-4 mx-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
							<span class="block sm:inline">{{getErrorUser.errorMessage}}</span>
							<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
								<svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
							</span>
						</div>
						<div v-if="!isPassword" class="mt-4 mx-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
							<span class="block sm:inline">Password and password confirmation are different</span>
							<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
								<svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
							</span>
						</div>
						
						<!--body-->
						<div class="relative p-6 flex-auto">
							<div class="grid grid-cols-2 gap-4">
								<div class="mb-4">
									<label for="password" class="block text-sm text-gray-600 dark:text-gray-400">Password</label>
									<input v-model="dataUser.password" type="password" name="password" id="password" placeholder="Password" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
								</div>
								<div class="mb-4">
									<label for="password2" class="block text-sm text-gray-600 dark:text-gray-400">Konfirmasi Password</label>
									<input v-model="dataUser.password2" type="password" name="password2" id="password2" placeholder="Konfirmasi Password" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-500 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
								</div>
							</div>
						</div>
						<!--footer-->
						<div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
							<button 
								type="button"
								class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2"
								@click="toggleChangePassword()">
								Batal
							</button>
							<button 
								type="submit"
								class="focus:outline-none text-white text-sm py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-900 hover:shadow-lg">
								Simpan Data
							</button>
						</div>
					</form>
					</div>
				</div>
			</div>

			<div v-if="openDetailData" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex bg-gray-400 bg-opacity-50">
				<div class="relative w-2/3 my-6 mx-auto max-w-xl">
					<!--content-->
					<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<!--header-->
					<form v-on:submit.prevent="editData">
						<div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
							<h3 class="text-xl font-semibold">
								Detail User
							</h3>
							<button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="createData()">
								<span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
									×
								</span>
							</button>
						</div>						
						<!--body-->
						<div class="relative p-6 flex-auto">
							<div class="mb-4">
								<strong for="name" class="block text-base text-gray-600 dark:text-gray-400">Nama</strong>
								<p class="pt-2 pl-4">{{dataUser.name}}</p>
							</div>
							<div class="mb-4">
								<strong for="name" class="block text-base text-gray-600 dark:text-gray-400">Email</strong>
								<p class="pt-2 pl-4">{{dataUser.email}}</p>
							</div>
							<div class="mb-4">
								<strong for="name" class="block text-base text-gray-600 dark:text-gray-400">NIP</strong>
								<p class="pt-2 pl-4">{{dataUser.nip}}</p>
							</div>
							<div class="mb-4">
								<strong for="name" class="block text-base text-gray-600 dark:text-gray-400">OPD</strong>
								<p class="pt-2 pl-4">{{dataUser.opd.name}}</p>
							</div>
							<div class="mb-4">
								<strong for="name" class="block text-base text-gray-600 dark:text-gray-400">Role</strong>
								<p class="pt-2 pl-4">{{dataUser.role.name}}</p>
							</div>
						</div>
						<!--footer-->
						<div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
							<button 
								type="button"
								class="focus:outline-none text-gray-600 text-sm py-2 px-5 rounded-md border border-gray-600 hover:bg-gray-50 mr-2"
								@click="toggleDetailData()">
								Tutup
							</button>
						</div>
					</form>
					</div>
				</div>
			</div>
	</main>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import Paginate from 'vuejs-paginate';
export default {
  name: "User",
  components: {
    Paginate
  },
	data() {
		return {
			openCreateData: false,
			openEditData: false,
			openDetailData: false,
			openChangePassword: false,
			isPassword: true,
			currentSort: 'updatedAt',
			currentSortType: 'desc',
			search: {
				attribute_name: 'search-users',
				keyword: ''
			},
			dataUser: {
				id: '',
				name: '',
				email: '',
				nip: '',
				password: '',
				password2: '',
				opd: {
					id: '',
					name: ''
				},
				role: {
					id: '',
					name: ''
				}
			}
		}
	},
	created() {
		this.fetchAllUsers({
			attribute_name: this.search.attribute_name,
      keyword: this.search.keyword,
      pageNumber: 1,
      pageSize: 10,
			sortName: this.currentSort,
			sortType: this.currentSortType
		});
		this.fetchAllOpds();
		this.fetchAllRoles();
	},
	computed: {
		...mapGetters({
			getUsers: 'users/getUsers',
			getOpds: 'opds/getOpds',
			getRoles: 'roles/getRoles',
			getErrorUser: 'users/getError'
		})
	},
	methods: {
		...mapActions({
			fetchAllUsers: 'users/fetchAllUsers',
			fetchAllOpds: 'opds/fetchAllOpds',
			fetchAllRoles: 'roles/fetchAllRoles',
			createUser: 'users/createUser',
			updateUser: 'users/updateUser'
		}),
		toggleCreateData() {
			this.openCreateData = !this.openCreateData;
			this.resetDataUser()
		},
		toggleEditData() {
			this.openEditData = !this.openEditData;
			this.resetDataUser()
		},
		toggleDetailData() {
			this.openDetailData = !this.openDetailData;
			this.resetDataUser()
		},
		toggleChangePassword() {
			this.openEditData = !this.openEditData;
			this.openChangePassword = !this.openChangePassword;
		},
		createData() {
			let token = localStorage.getItem("token")
			
			const config = {
				'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
			};

			const raw = JSON.stringify({
				data: {
					type: "users",
					attributes: {
						name: this.dataUser.name,
						email: this.dataUser.email,
						nip: this.dataUser.nip,
						password: this.dataUser.password,
						password_confirmation: this.dataUser.password2
					},
					relationships: {
						opd: {
							data: {
								type: "opds",
								id: this.dataUser.opd.id
							}
						},
						roles: {
							data: [{
								type: "roles",
								id: this.dataUser.role.id
							}]
						}
					}
				}
			})
			console.log(raw)
			if(this.dataUser.password === this.dataUser.password2){
				this.isPassword = true
				this.createUser({
					data: raw,
					config: config
				}).then(() => {
					if(this.getErrorUser.errorCode == 0){
						this.openCreateData = !this.openCreateData
						this.resetDataUser()
						location.reload()
					}
				})
			}else{
				this.isPassword = false
			}
		},
		resetDataUser(){
			this.dataUser = {
				id: '',
				name: '',
				email: '',
				nip: '',
				password: '',
				password2: '',
				opd: {
					id: '',
					name: ''
				},
				role: {
					id: '',
					name: ''
				}
			}
		},
		detailData(user) {
			this.openDetailData = !this.openDetailData;
			this.dataUser = {
				id: user.id,
				name: user.attributes.name,
				email: user.attributes.email,
				nip: user.attributes.nip,
				opd: {
					id: this.getIncluded(this.getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type).id,
					name: this.getIncluded(this.getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type).attributes.name
				},
				role: {
					id: (user.relationships.roles.data[0] !== undefined) ? this.getIncluded(this.getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).id : "",
					name: (user.relationships.roles.data[0] !== undefined) ? this.getIncluded(this.getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).attributes.name : "-"
				}
			}
		},
		editData(user) {
			this.openEditData = !this.openEditData;
			this.dataUser = {
				id: user.id,
				name: user.attributes.name,
				email: user.attributes.email,
				nip: user.attributes.nip,
				opd: {
					id: this.getIncluded(this.getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type).id,
					name: this.getIncluded(this.getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type).attributes.name
				},
				role: {
					id: (user.relationships.roles.data[0] !== undefined) ? this.getIncluded(this.getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).id : "",
					name: (user.relationships.roles.data[0] !== undefined) ? this.getIncluded(this.getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).attributes.name : "-"
				}
			}
		},
		changePassword(){
			console.log(this.dataUser)
			let token = localStorage.getItem("token")
			
			const config = {
				'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
			};

			const raw = JSON.stringify({
				data: {
					type: "users",
					id: this.dataUser.id,
					attributes: {
						password: this.dataUser.password,
						password_confirmation: this.dataUser.password2
					}
				}
			})

			if(this.dataUser.password === this.dataUser.password2){
				this.isPassword = true
				this.updateUser({
					data: raw,
					config: config
				}).then(() => {
					if(this.getErrorUser.errorCode == 0){
						this.openChangePassword = !this.openChangePassword
						this.resetDataUser()
						location.reload()
					}
				})
			}else{
				this.isPassword = false
			}
		},
		updateData(){
			let token = localStorage.getItem("token")
			
			const config = {
				'Accept' : 'application/vnd.api+json',
        'Content-Type' : 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
			};

			const raw = JSON.stringify({
				data: {
					type: "users",
					id: this.dataUser.id,
					attributes: {
						name: this.dataUser.name,
						email: this.dataUser.email,
						nip: this.dataUser.nip
					},
					relationships: {
						opd: {
							data: {
								type: "opds",
								id: this.dataUser.opd.id
							}
						},
						roles: {
							data: [{
								type: "roles",
								id: this.dataUser.role.id
							}]
						}
					}
				}
			})
			this.updateUser({
				data: raw,
				config: config
			}).then(() => {
				if(this.getErrorUser.errorCode == 0){
					this.openEditData = !this.openEditData
					this.resetDataUser()
					location.reload()
				}
			})
		},
		deleteData(user){
			this.dataUser = {
				id: user.id,
				name: user.attributes.name,
				email: user.attributes.email,
				nip: user.attributes.nip,
				opd: {
					id: this.getIncluded(this.getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type).id,
					name: this.getIncluded(this.getUsers.included, user.relationships.opd.data.id, user.relationships.opd.data.type).attributes.name
				},
				role: {
					id: (user.relationships.roles.data[0] !== undefined) ? this.getIncluded(this.getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).id : "",
					name: (user.relationships.roles.data[0] !== undefined) ? this.getIncluded(this.getUsers.included, user.relationships.roles.data[0].id, user.relationships.roles.data[0].type).attributes.name : "-"
				}
			}

			if(confirm("Do you really want to delete?")){
				let token = localStorage.getItem("token")
			
				const config = {
					'Accept' : 'application/vnd.api+json',
					'Content-Type' : 'application/vnd.api+json',
					'Authorization': `Bearer ${token}`,
				};

				const raw = JSON.stringify({
					data: {
						type: "users",
						id: this.dataUser.id,
						attributes: {
							deleted: true
						},
						relationships: {
							opd: {
								data: {
									type: "opds",
									id: this.dataUser.opd.id
								}
							},
							roles: {
								data: [{
									type: "roles",
									id: this.dataUser.role.id
								}]
							}
						}
					}
				})
				this.updateUser({
					data: raw,
					config: config
				}).then(() => {
					this.resetDataUser()
					location.reload()
				})
			}
			this.resetDataUser()
		},
		searchData(){
			this.fetchAllUsers({
				attribute_name: this.search.attribute_name,
				keyword: this.search.keyword,
				pageNumber: 1,
				pageSize: 10,
				sortName: this.currentSort,
				sortType: this.currentSortType
			})
		},
		sortData(sortName){
			if(sortName === this.currentSort){
				this.currentSortType = this.currentSortType === 'asc' ? 'desc' : 'asc'
				this.fetchAllUsers({
					attribute_name: this.search.attribute_name,
					keyword: this.search.keyword,
					pageNumber: 1,
					pageSize: 10,
					sortName: this.currentSort,
					sortType: this.currentSortType
				})
			} else {
				this.currentSort = sortName
				this.currentSortType = 'asc'
				this.fetchAllUsers({
					attribute_name: this.search.attribute_name,
					keyword: this.search.keyword,
					pageNumber: 1,
					pageSize: 10,
					sortName: this.currentSort,
					sortType: this.currentSortType
				})
			}
		},
		paginateUsers(pageNumber){
			this.fetchAllUsers({
        attribute_name: this.search.attribute_name,
				keyword: this.search.keyword,
				pageNumber: pageNumber,
				pageSize: 10,
				sortName: this.currentSort,
				sortType: this.currentSortType
      })
		},
		getIncluded(resource, id, type){
			const test = resource.filter((include) => {
				if(include.type == type && include.id == id){
					return include
				}
			}).map(function(data){
				return data
			})
			return test[0]
		}
	}
};
</script>
<style>
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  list-style: none;  
  margin-top: 0;
  margin-bottom: 16px;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 6px;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block; 
  color: #2c3e50;
  content: '/';
}
.breadcrumb-item a {
  font-size: 14px;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}
.breadcrumb-item span {
  color: #0471d8;
}
</style>