import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  error: {
    errorMessage: '',
    errorCode: 0
  },
  opds: {
    data: {
      id: '',
      attributes: {
        name: ''
      }
    }
  },
  opd: {
    attributes: {
      
    }
  },
  paginateOpds: {
    meta: {
      page: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0
      }
    },
    data: {},
    included: {}
  },
  sector: [],
  userOpd: []
};

const getters = {
  getOpds: (state) => {
    return state.opds
  },

  getPaginateOpds: (state) => {
    return state.paginateOpds
  },

  getOpd: (state) => {
    // return console.log("OPD: "+JSON.stringify(state.opd.data.attributes.name))
    return state.opd
  },

  getTotalOpds: (state) => {
    return state.opds.length
  },

  getUser: (state) => {
    // return console.log("LOCAL STORAGE: "+JSON.stringify(state.userOpd))
    return state.userOpd
  },

  getName: (state) => {
    // return console.log("LOCAL STORAGE: "+JSON.stringify(state.userOpd))
    return state.OpdName
  },

  getSector: (state) => {
    return state.sector
  },

  getError: (state) => {
    return state.error
  }
};

const mutations = {
  REQUEST_OPDS(state, status) {
    state.isLoading = status
  },

  SET_OPDS(state, response) {
    state.opds = response
  },

  SET_PAGINATE_OPDS(state, response) {
    state.paginateOpds = response
  },

  SET_OPD(state, opd) {
    state.opd = opd
  },

  SET_SECTOR(state, sector) {
    state.sector = sector
  },

  SET_ERROR(state, {errorMessage, errorCode}){
    state.error.errorMessage = errorMessage
    state.error.errorCode = errorCode
  }
};

const actions = {
  async createOpd({commit}, {data, config}){
    commit('REQUEST_OPDS', true)
    try {
      let response = await ApiService.post('/api/v1/opds', data, config)
      commit('SET_OPD', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_OPDS', false)
  },

  async updateOpd({commit}, {data, config}){
    commit('REQUEST_OPDS', true)
    // const form = JSON.parse(data)
    try {
      let response = await ApiService.patch('/api/v1/opds/' + data.data.id, data, config)
      commit('SET_OPD', response.data)
      commit('SET_ERROR', {
        errorMessage: '',
        errorCode: 0
      })
    } catch(error) {
      console.log(error)
      commit('SET_ERROR', {
        errorMessage: error.response.data.errors[0].detail,
        errorCode: error.response.data.errors[0].status
      })
    }
    commit('REQUEST_OPDS', false)
  },
  

  async fetchAllOpds({commit}) {
    commit('REQUEST_OPDS', true)

    try {
      let response = await ApiService.get('/api/v1/opds')
      let total = response.data.meta.page.total
      let totalResponse = await ApiService.get('/api/v1/opds?page[number]=1&page[size]=' + total )
      commit('SET_OPDS', totalResponse.data)
    } catch (e) {
      console.log('FetchAllOpds : ' + e)
    }
    commit('REQUEST_OPDS', false)
  },

  async fetchPaginateOpds({commit}, {attribute_name, keyword, pageNumber, pageSize, sortName, sortType}) {
    commit('REQUEST_OPDS', true)
    if(sortType=='desc'){
      sortName = '-' + sortName
    }
    try {
      let response = await ApiService.get('/api/v1/opds?include=sectors,themes&filter[' + attribute_name + ']=' + keyword + '&page[number]=' + pageNumber + '&page[size]=' + pageSize + '&sort=' + sortName)
      commit('SET_PAGINATE_OPDS', response.data)
    } catch (e) {
      console.log('FetchAllOpds : ' + e)
    }
    commit('REQUEST_OPDS', false)
  },

  async fetchOpdByID({commit}, {id}) {
    let userOpd
    if(id){
      userOpd = id
    } else {
      userOpd = JSON.parse(localStorage.getItem('me')).opd_id;
    }
    commit('REQUEST_OPDS', true)

    try {
      let response = await ApiService.get('/api/v1/opds/'+ userOpd + '?include=sectors,themes')
      let opd = response.data.data;
      commit('SET_OPD', opd)
      return response;
    } catch (e) {
      console.log('FetchOpdByID: ' + e)
    }
    commit('REQUEST_OPDS', false)
  },

  async fetchSectorsByOpd({commit}, rik_id) {    
    let userOpd = JSON.parse(localStorage.getItem('me')).opd_id;
    commit('REQUEST_OPDS', true)
    console.log(rik_id)
    try {
      let response = await ApiService.get('/api/v1/opds/'+ userOpd + '/sectors?filter[rik-id]=' + rik_id)
      console.log(response.data)
      let sectors = response.data.data;
      commit('SET_SECTOR', sectors)
    } catch (e) {
      console.log('Fetch Sector by RIK ID got error: ' + e)
    }
    commit('REQUEST_OPDS', false)
  }
}

export default {
	namespaced: true,
  state,
  getters,
  mutations,
  actions
}
