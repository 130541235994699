<template>
  <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
		<div class="my-10 space-y-8 divide-y divide-gray-200" >
			<div class="space-y-8 divide-y divide-gray-200">
					<div>
						<h3 class="text-lg leading-6 font-medium text-gray-900">
								Daftar Usulan
						</h3>
					</div>

          <div class="mt-8 px-2 sm:px-0" v-for="proposal in getProposalsByUser" :value="proposal.id" :key="proposal.id">
              <ul class="space-y-4">
                <li class="bg-white px-4 py-6 shadow sm:p-6 rounded-lg border">
                  <article aria-labelledby="list-user-studies">
                    <div>
                      <div class="flex justify-between items-baseline md:block lg:flex">
                        <router-link
                          :to="'/user/detailusulan/'+proposal.id"
                          class="text-base font-medium text-gray-900 hover:text-indigo-700"
                        >
                          <span class="flex items-baseline text-2xl">
                            {{ proposal.attributes.title }}
                          </span>
                        </router-link>
                        <div class="flex justify-between self-center md:block lg:flex">
                          <div :class="[(proposal.attributes.status === 'approved' ? 'status-approved' : ''), (proposal.attributes.status === 'banned' ? 'status-rejected' : ''), (proposal.attributes.status === 'submitted' ? 'status-submited' : ''), (proposal.attributes.status === 'draft' ? 'status-draft' : '')]">
                            {{ proposal.attributes.status }}
                          </div>
                        </div>
                      </div>
                      <h2 class="text-base font-medium text-gray-900 text-xs mt-2">
                      <span class="text-sm font-normal">Disusun Oleh:</span> {{proposal.attributes.team}}</h2>
                    </div>
                    <div class="mt-2 text-base text-gray-700 overflow-ellipsis overflow-hidden">
                      <p>
                        {{proposal.attributes.background}}
                      </p>
                    </div>
                  </article>
                </li>
              </ul>
          </div>

          <paginate 
            :page-count="parseInt(getPageCount)"
            :page-range="2"
            :click-handler="paginateProposals"
            :prev-text="'Prev'"
            :prevClass="'-mt-px w-0 flex-1 flex'"
            :prev-link-class="'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
            :next-text="'Next'"
            :nextClass="'-mt-px w-0 flex-1 flex justify-end'"
            :next-link-class="'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'"
            :page-class="'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
            :activeClass="'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'"
            :container-class="'border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'">
          </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Paginate from 'vuejs-paginate';
export default {
  components: {
    'paginate': Paginate
  },

  data: function () {
    return {
      // isOpen: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      getProposalsByUser: 'proposals/getProposalsByUser',
      getPageCount: 'proposals/getPageCountUserProposals'
    })

  },
  methods: {
    ...mapActions({
      fetchProposal: 'proposals/fetchProposalsByUser'
    }),
    paginateProposals(pageNumber){
      this.fetchProposal({
        pageNumber: pageNumber,
        pageSize: 10
      })
    }
  },
  created(){
    this.fetchProposal({
      pageNumber: 1,
      pageSize: 10
    })
  }
};
</script>

<style lang="postcss">
.status-draft{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800 md:mt-2 lg:mt-0;
},

.status-approved{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0;
},

.status-submited{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800 md:mt-2 lg:mt-0;
}

.status-rejected{
  @apply inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0;
}
</style>
