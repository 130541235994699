import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ApiService from '@/services/api.service'
import { StorageService } from '@/services/storage.service'
import VueFilterTruncate from '@vuejs-community/vue-filter-truncate';
import VueBreadcrumbs from 'vue-2-breadcrumbs';

Vue.config.productionTip = false
Vue.use(VueFilterTruncate);
Vue.use(VueBreadcrumbs);

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_BASEURL)

// If token exists set header
if (StorageService.getToken()) {
  ApiService.setHeader()
}

// axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
// axios.defaults.headers.get['Content-Type'] = 'application/json'

import "@/assets/main.css"

new Vue({
  name: "SIHK",
  data: {
    appVersion: "0.1"
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
