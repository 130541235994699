import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  roles: {
    id: '',
    attributes: {
      name: ''
    }
  }
};

const getters = {
  getRoles: (state) => {
    return state.roles
  },

  getTotalRoles: (state) => {
    return state.roles.length
  }
};

const mutations = {
  REQUEST_ROLES(state) {
    state.isLoading = true
  },

  SET_ROLES(state, response) {
    state.roles = response.data
  }
};

const actions = {
  async fetchAllRoles({commit}) {
    commit('REQUEST_ROLES')
    try {
      let response = await ApiService.get('/api/v1/roles')
      commit('SET_ROLES', response.data)
    } catch (e) {
      console.log('FetchAllRoles : ' + e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}