<template>
  <div>
    <div class="flex-1 flex mx-auto">
      <a
        href="/"
        class="border-transparent p-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
      >
        <svg
          class="mr-3 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        Kembali ke Halaman Depan
      </a>
    </div>

    <div class="h-screen bg-white flex flex-col justify-center items-center">
      <div class="container shadow-2xl max-w-sm ring-2 rounded ring-indigo-400">
        <div class="max-w-md mx-auto my-10">
          <div class="text-center">
            <h1
              class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200"
            >
              Sign in
            </h1>
            <p class="text-gray-500 dark:text-gray-400">
              Sign in to access your account
            </p>
          </div>
          <div class="m-7">
            <div
              v-if="authenticationError !== '' && authenticationErrorCode !== 0"
              class="rounded-md bg-red-50 p-4"
            >
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/check-circle -->
                  <svg
                    class="h-5 w-5 text-red-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">
                    {{ authenticationErrorCode }} || {{ authenticationError }}
                  </p>
                </div>
                <!-- <div class="ml-auto pl-3">
                  <div class="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                    >
                      <span class="sr-only">Dismiss</span>
                      <svg
                        class="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div> -->
              </div>
            </div>

            <form>
              <div class="my-6">
                <label
                  for="nip"
                  class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >Email</label
                >
                <input
                  required
                  type="text"
                  v-model="nip"
                  id="nip"
                  placeholder="Masukkan Email"
                  class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                />
              </div>
              <div class="mb-6">
                <div class="flex justify-between mb-2">
                  <label
                    for="password"
                    class="text-sm text-gray-600 dark:text-gray-400"
                    >Password</label
                  >
                </div>
                <input
                  required
                  type="password"
                  v-model="password"
                  id="password"
                  placeholder="Your Password"
                  class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                />
              </div>
              <div class="mb-6">
                <button
                  @click="doLogin"
                  type="button"
                  class="w-full px-3 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Mentions -->
    <footer class="min-h-screen flex justify-center px-4 text-gray-100 bg-gray-800">
      <div class="container py-6">
        <h1 class="text-center text-sm font-bold">
          All Right Reserved, BPKA Pemda DIY 2020
        </h1>
      </div>
    </footer>
    <!-- <div class="fixed top-0 w-full text-center text-gray-400 -mb-6 z-0">
      <p>BPPSD SIHK © 2021</p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path
          fill="#0099ff"
          fill-opacity="1"
          d="M0,160L120,176C240,192,480,224,720,218.7C960,213,1200,171,1320,149.3L1440,128L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Login",
  data: () => ({
    nip: "",
    password: "",
    msgAlert: false,
  }),
  computed: {
    ...mapGetters({
      authenticationError: "login/authenticationError",
      authenticationErrorCode: "login/authenticationErrorCode",
    }),
  },
  methods: {
    ...mapActions({
      login: "login/login",
      fetchCurrentUser: "users/fetchCurrentUser"
    }),
    reset() {
      this.$refs.form.reset();
    },
    doLogin() {
      const { nip, password } = this;
      if (this.nip != "" && this.password != "") {
        this.$store
          .dispatch("login/login", { email: nip, password })
          .then(() => {
            this.fetchCurrentUser().then((response) => {
              const role = this.getRelationships(response.data, response.data.data.relationships['roles'].data[0].id).attributes.name
              localStorage.setItem('role', role)
              if(role === 'user'){
                this.$router.push("/user/dashboard")
              } else {
                this.$router.push("/admin/dashboard")  
              }
            })
          })
          .catch((err) => console.log(err));
      } else {
        this.msgAlert = true;
      }
    },
    getRelationships(resource, id){
      let data = resource.included.filter(function (el) {
        return el.id === id
      });
      return data[0]
    },
  },
};
</script>
